import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "@reduxjs/toolkit";

import userReducer from "./user.slice";
import customizationReducer from "features/theme/customizationReducer";
import userSliceGs from "./userSliceGs";
import {frameworkReducer} from "./settingReducer"
export const store = configureStore({
  reducer: {
    user: userReducer,
    customization: customizationReducer,
    userGs: userSliceGs,
    activeFramework: frameworkReducer
  },
});
