// project imports
import config from "constant/config";

// action - state management
import * as actionTypes from "../features/theme/actions";

export const initialState = {
  userDetails: {},
  userData: {},
};

// ==============================|| USER REDUCER ||============================== //

const userReducer = (state = initialState, action) => {
  let id;
  switch (action.type) {
    case actionTypes.SET_LOGIN:
      return {
        ...state,
        userDetails: action?.id,
      };
    case actionTypes.SET_USER:
      return {
        ...state,
        userData: action?.userDetails,
      };
    case actionTypes.SET_LOGOUT:
      return initialState;

    default:
      return state;
  }
};

export default userReducer;
