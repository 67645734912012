// userSlice.js

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  userDetails: {},
  userData: {},
};

const userSliceGs = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setLogin: (state, action) => {
      state.userDetails = action.payload;
    },
    setUser: (state, action) => {
      state.userData = action.payload;
    },
    setLogout: (state) => {
      state = initialState;
    },
  },
});

export const { setLogin, setUser, setLogout } = userSliceGs.actions;
export default userSliceGs.reducer;
