import React, { useEffect, useReducer } from "react";
import { Box, Grid, Typography, Button } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import Spinner from "common_components/PageLoader";
import { commonService } from "helpers/common.service";
import TransitionsModal from "./TransitionsModal";
import { Failed } from "common_components/toaster/toaster";
import "./flexlist.scss";

type State = {
  claimantDetails: any;
  loading: boolean;
  modalOpen: boolean;
};

type Action =
  | { type: "SET_CLAIMANT_DETAILS"; payload: any }
  | { type: "SET_LOADING"; payload: boolean }
  | { type: "SET_MODAL_OPEN"; payload: boolean };

const initialState: State = {
  claimantDetails: null,
  loading: false,
  modalOpen: false,
};

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case "SET_CLAIMANT_DETAILS":
      return { ...state, claimantDetails: action.payload };
    case "SET_LOADING":
      return { ...state, loading: action.payload };
    case "SET_MODAL_OPEN":
      return { ...state, modalOpen: action.payload };
    default:
      return state;
  }
};

const ClaimantView: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    if (location.state?.idPass) {
      const policyRefId = location.state.idPass;
      fetchClaimantDetails(policyRefId);
    }
  }, [location.state]);

  const formatKey = (key: string) =>
    key
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");

  const fetchClaimantDetails = async (policyRefId: string) => {
    dispatch({ type: "SET_LOADING", payload: true });
    try {
      const response = await commonService.getServices(`/claim/${policyRefId}`);
      if (response.status === 200) {
        dispatch({ type: "SET_CLAIMANT_DETAILS", payload: response.data?.data[0] });
      }
    } catch (error: any) {
      Failed(error?.response?.data?.message);
    } finally {
      dispatch({ type: "SET_LOADING", payload: false });
    }
  };

  const downloadFile = () => {
    if (state.claimantDetails?.policy_reference_id) {
      navigate("/user/view_document", {
        state: { id: state.claimantDetails.policy_reference_id },
      });
    }
  };

  const renderKeyValuePairs = () => {
    if (!state.claimantDetails) return null;

    const entries = Object.entries(state.claimantDetails);
    const third = Math.ceil(entries.length / 3);
    const columns = [
      entries.slice(0, third),
      entries.slice(third, 2 * third),
      entries.slice(2 * third),
    ];

    const renderColumn = (columnEntries: [string, any][]) =>
      columnEntries.map(([key, value]) => (
        <Box key={key}>
          <div className="flex-list">
            <Typography variant="body1" sx={{ width: "50%", fontSize: "14px" }}>
              <strong>{formatKey(key)}</strong>
            </Typography>
            <Typography variant="body1" sx={{ fontSize: "14px" }}>
              {value ?? <div>-</div>}
            </Typography>
          </div>
          <hr className="custom-hr" />
        </Box>
      ));

    return (
      <Grid container spacing={2} sx={{ position: "relative" }}>
        {columns.map((column, index) => (
          <Grid item xs={12} md={4} key={index}>
            {renderColumn(column)}
          </Grid>
        ))}
      </Grid>
    );
  };

  const goBack = () => {
    navigate(-1);
  };

  return (
    <>
      {state.loading && <Spinner />}
      <Box>
        <Box className="Box-custom" sx={{ marginTop: "2rem", height: "75vh", overflowY: "auto" }}>
          {renderKeyValuePairs()}
        </Box>
        <Box sx={{ display: "flex", justifyContent: "space-between", margin: "25px 217px 25px 130px", gap: "16rem" }}>
          <Button
            onClick={goBack}
            variant="outlined"
            sx={{
              padding: "14px 37px",
              color: "Black",
              border: "0",
              background: "White",
              "&:hover": { background: "#0e0aed", color: "white" },
              borderRadius: "8px",
            }}
          >
            Back
          </Button>
          <Box sx={{ display: "flex", gap: "10px" }}>
            <Button
              variant="outlined"
              sx={{
                padding: "9px 37px",
                color: "white",
                border: "0",
                background: "#5553CF",
                "&:hover": { background: "#0e0aed", color: "white" },
                borderRadius: "8px",
              }}
              onClick={downloadFile}
            >
              View Document
            </Button>
            <Button
              variant="outlined"
              sx={{
                padding: "9px 37px",
                color: "white",
                border: "0",
                background: "black",
                "&:hover": { background: "#0e0aed", color: "white" },
                borderRadius: "8px",
              }}
              onClick={() => dispatch({ type: "SET_MODAL_OPEN", payload: true })}
            >
              <TransitionsModal
                policyId={state.claimantDetails?.policy_reference_id}
                policyNumber={state.claimantDetails?.carrier_claim_number}
              />
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default ClaimantView;
