import React, { useState, useEffect } from "react";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Paper,
    Button,
    Typography,
    CircularProgress,
    Box,
} from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import { Failed, Success } from "common_components/toaster/toaster";
import { commonService } from "helpers/common.service";

const ChatHistory = () => {
    const [rows, setRows] = useState<any>([]);
    const [serviceCall, setServiceCall] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);

    const getViewDocument = async () => {
        setLoading(true);
        setError(null);
        try {
            const fileDownload = await commonService.getServices(`/session_id`);
            const sortedData = fileDownload?.data.map((chatType: any) => ({
                ...chatType,
                data: chatType.data.sort((b: any, a: any) => new Date(a.date).getTime() - new Date(b.date).getTime()),
            }));
            setRows(sortedData || []);
        } catch (error: any) {
            setError(error?.response?.data?.message || "Failed to load data");
            console.error(error?.response?.data?.message);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getViewDocument();
    }, []);

    const handleDownload = async (id: any, filename: string, chartType: string) => {
        setLoading(true);
        try {
            const response = await commonService.pdfService(`chat_history?session_id=${id}&chat_type=${chartType}&date=${filename}`);
            const blob = new Blob([response.data], { type: "application/pdf" });
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", filename);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);
        } catch (error: any) {
            Failed(error?.response?.data?.message);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Box sx={{ height: "30vh", overflowY: "auto", padding: "17px", position: 'relative' }}>
            {loading && (
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        backgroundColor: 'rgba(255, 255, 255, 0.8)', // Optional: Adds a semi-transparent background
                        zIndex: 1,
                    }}
                >
                    <CircularProgress />
                </Box>
            )}
            {error ? (
                <Typography color="error">{error}</Typography>
            ) : rows.length > 0 ? (
                <div>
                    {rows.map((chatType: any, index: number) => (
                        <div key={index}>
                            <Typography variant="h3" gutterBottom>
                                {chatType.chat_type}
                            </Typography>
                            <TableContainer component={Paper}>
                                <Table>
                                    <TableBody>
                                        {chatType.data.map((item: any) => (
                                            <TableRow key={item.session_id}>
                                                <TableCell>{item.date}</TableCell>
                                                <TableCell>
                                                    <Button onClick={() => handleDownload(item.session_id, item.date,chatType.chat_type)}>
                                                        <DownloadIcon />
                                                    </Button>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>
                    ))}
                </div>
            ) : (
                <Typography>No data available</Typography>
            )}
        </Box>
    );
};

export default ChatHistory;
