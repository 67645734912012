// src/redux/reducers.ts
import { SET_FRAMEWORK, SET_SAVE_FRAMEWORK_VERSION, SET_SAVE_FRAMEWORK_VERSION_CMP, SET_LOADER } from './actionTypes';

const initialState = {
  frameworkData: "",
  saveFrameworkVersion: "",
  saveFrameworkVersionCmp: "",
  loading: false,
};

export const frameworkReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case SET_FRAMEWORK:
      return { ...state, frameworkData: action.payload };
    case SET_SAVE_FRAMEWORK_VERSION:
      return { ...state, saveFrameworkVersion: action.payload };
    case SET_SAVE_FRAMEWORK_VERSION_CMP:
      return { ...state, saveFrameworkVersionCmp: action.payload };
    case SET_LOADER:
      return { ...state, loading: action.payload };
    default:
      return state;
  }
};
