import React, { useReducer, useEffect, useCallback } from "react";
import VolumeMuteIcon from '@mui/icons-material/VolumeMuteRounded';
import VolumeUpIcon from '@mui/icons-material/VolumeDownRounded';
import { Tooltip } from "@mui/material";


interface TextToSpeechProps {
  text: string;
  playText: () => void;
}

interface State {
  isPaused: boolean;
  isPlaySpeech: boolean;
  utterance: SpeechSynthesisUtterance | null;
}

type Action =
  | { type: "SET_UTTERANCE"; payload: SpeechSynthesisUtterance }
  | { type: "SET_PAUSED"; payload: boolean }
  | { type: "SET_PLAY_SPEECH"; payload: boolean };

const initialState: State = {
  isPaused: false,
  utterance: null,
  isPlaySpeech: false,
};

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case "SET_UTTERANCE":
      return { ...state, utterance: action.payload };
    case "SET_PAUSED":
      return { ...state, isPaused: action.payload };
    case "SET_PLAY_SPEECH":
      return { ...state, isPlaySpeech: action.payload };
    default:
      return state;
  }
};

const TextToSpeech: React.FC<TextToSpeechProps> = ({ text, playText }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    dispatch({ type: "SET_PLAY_SPEECH", payload: true });
    if (state.isPlaySpeech) {
      const synth = window.speechSynthesis;
      const u = new SpeechSynthesisUtterance(text);

      u.onend = () => {
        dispatch({ type: "SET_PAUSED", payload: false });
      };

      dispatch({ type: "SET_UTTERANCE", payload: u });

      return () => {
        synth.cancel();
      };
    }

  }, [state.isPlaySpeech, text]);

  const handlePlay = () => {
    const synth = window.speechSynthesis;
    dispatch({ type: "SET_PLAY_SPEECH", payload: true });

    if (synth.speaking) {
      if (state.isPaused) {
        synth.resume();
      } else {
        dispatch({ type: "SET_PLAY_SPEECH", payload: false });
      }
    } else {
      if (state.utterance) {
        synth.speak(state.utterance);
      }

    }

  };

  const handlePause = () => {
    const synth = window.speechSynthesis;

    if (synth.speaking && !state.isPaused) {
      synth.pause();
      dispatch({ type: "SET_PAUSED", payload: true });
    }
  };

  const handleStop = () => {
    const synth = window.speechSynthesis;

    if (synth.speaking) {
      synth.cancel();
    }
    dispatch({ type: "SET_PLAY_SPEECH", payload: false });
    dispatch({ type: "SET_PAUSED", payload: false });
  };

  useEffect(() => {
    const synth = window.speechSynthesis;
    if (state.isPlaySpeech) {
      playText();
    }

  }, [playText, state.isPlaySpeech]);

  return (
    <div>
      <div style={{ cursor: "pointer" }}>
        {state.isPlaySpeech ? (
          <Tooltip title="Loud">
            <VolumeUpIcon onClick={handlePlay} style={{ fontSize: 35,  opacity: '0.7' }} />
          </Tooltip>
        ) : (

          <VolumeMuteIcon onClick={handleStop} style={{ fontSize: 35 }} />

        )}
      </div>
    </div>
  );
};

export default TextToSpeech;
