import React, { useReducer } from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Loading from "../../common_components/PageLoader/index";
import { Failed } from "common_components/toaster/toaster";
import ThumbDownAltIcon from '@mui/icons-material/ThumbDownAltRounded';
import InputLabel from '@mui/material/InputLabel';
import { FormControl, Select, MenuItem, Tooltip } from "@mui/material";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: "15px",
};

const textFieldStyle = {
  ".MuiOutlinedInput-notchedOutline": {
    borderRadius: "15px",
    borderWidth: "0",
  },
  ".MuiOutlinedInput-root": {
    borderRadius: "15px",
  },
  ".MuiOutlinedInput-input": {
    height: "1em",
    fontSize: "20px",
    backgroundColor: "#F5F7FA",
    borderRadius: "15px",
  },
  marginTop: "10px",
};

const initialState = {
  open: false,
  additionalField1: "",
  additionalField2: "",
  selectedCategory: "",
  loading: false,
  categories: [
    { value: "Partially misunderstood the instruction", label: "Partially misunderstood the instruction" },
    { value: "Taking too long to respond", label: "Taking too long to respond" },
    { value: "Didn't entirely follow the instruction", label: "Didn't entirely follow the instruction" },
    { value: "Erroneous data", label: "Erroneous data" },
    { value: "Factually inaccurate", label: "Factually inaccurate" },
  ],
};

type State = typeof initialState;

type Action =
  | { type: "OPEN_MODAL" }
  | { type: "CLOSE_MODAL" }
  | { type: "SET_ADDITIONAL_FIELD1"; payload: string }
  | { type: "SET_ADDITIONAL_FIELD2"; payload: string }
  | { type: "SET_SELECTED_CATEGORY"; payload: string }
  | { type: "SET_LOADING"; payload: boolean };

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case "OPEN_MODAL":
      return { ...state, open: true };
    case "CLOSE_MODAL":
      return { ...state, open: false, additionalField1: "", additionalField2: "", selectedCategory: "" };
    case "SET_ADDITIONAL_FIELD1":
      return { ...state, additionalField1: action.payload };
    case "SET_ADDITIONAL_FIELD2":
      return { ...state, additionalField2: action.payload };
    case "SET_SELECTED_CATEGORY":
      return { ...state, selectedCategory: action.payload };
    case "SET_LOADING":
      return { ...state, loading: action.payload };
    default:
      return state;
  }
}

interface DislikePopupProps {
  onSubmit: (selectedCategory: string, additionalField2: string,) => void;
  IconColor: boolean;
}

export default function DislikePopup({ onSubmit, IconColor }: DislikePopupProps) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const handleUpload = async () => {
    dispatch({ type: "SET_LOADING", payload: true });
    try {
      // Submit the data to the parent component
      onSubmit(state.additionalField2, state.selectedCategory);
      dispatch({ type: "SET_LOADING", payload: false });
      dispatch({ type: "CLOSE_MODAL" });
    } catch (error: any) {
      dispatch({ type: "SET_LOADING", payload: false });
      Failed(error.response.data.message);
    }
  };

  return (
    <div>
      <Tooltip title="DisLike">
        <ThumbDownAltIcon
          sx={{
            color: IconColor ? "red" : "black", fontSize: '23px', cursor: 'pointer',
            opacity: '0.5'
          }}
          onClick={() => dispatch({ type: "OPEN_MODAL" })}
        />
      </Tooltip>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={state.open}
        onClose={() => dispatch({ type: "CLOSE_MODAL" })}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 500 }}
      >
        <Fade in={state.open}>
          <Box sx={style}>
            <div style={{ textAlign: "center" }}>
              <InputLabel sx={{ marginTop: "10px", marginBottom: "10px", fontSize: "15px" }}>
                What difficulties are you facing?
              </InputLabel>
              <FormControl fullWidth>
                <Select
                  labelId="category-select-label"
                  id="category-select"
                  value={state.selectedCategory}
                  onChange={(e) => dispatch({ type: "SET_SELECTED_CATEGORY", payload: e.target.value })}
                  IconComponent={KeyboardArrowDownIcon}
                  sx={{
                    ".MuiOutlinedInput-notchedOutline": {
                      border: "none",
                    },
                    "&.MuiSelect-select": {
                      borderRadius: "15px",
                    },
                    ".MuiOutlinedInput-input": {},
                    borderRadius: "15px",
                  }}
                >
                  {state.categories.map((category) => (
                    <MenuItem key={category.value} value={category.value}>
                      {category.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {/* <InputLabel sx={{ marginTop: "10px", marginBottom: "10px", fontSize: "15px" }}>
                What difficulties are you facing?
              </InputLabel>
              <TextField
                fullWidth
                id="additional-field-1"
                value={state.additionalField1}
                onChange={(e) => dispatch({ type: "SET_ADDITIONAL_FIELD1", payload: e.target.value })}
                placeholder=""
                sx={textFieldStyle}
              /> */}

              <InputLabel sx={{ marginTop: "10px", marginBottom: "10px", fontSize: "15px" }}>
                Other improvements(if any)
              </InputLabel>
              <TextField
                fullWidth
                id="additional-field-2"
                value={state.additionalField2}
                onChange={(e) => dispatch({ type: "SET_ADDITIONAL_FIELD2", payload: e.target.value })}
                placeholder=""
                sx={textFieldStyle}
              />
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Button
                variant="outlined"
                sx={{ padding: "9px 37px", color: "black", background: "#dbd4d49e", "&:hover": { background: "#0e0aed", color: "white" }, borderRadius: "8px", marginTop: "10px" }}
                onClick={() => dispatch({ type: "CLOSE_MODAL" })}
              >
                Cancel
              </Button>
              <Button
                variant="outlined"
                sx={{
                  padding: "14px 37px",
                  color: "White",
                  background: "#0e0aed",
                  "&:hover": { background: "white", color: "black" },
                  borderRadius: "8px",
                  marginTop: "10px"
                }}
                onClick={handleUpload}
                disabled={!state.additionalField1 && !state.additionalField2 && !state.selectedCategory} // Enable if any one condition is true
              >
                {!state.loading ? "Submit" : <Loading />}
              </Button>
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
