import React, { useEffect, useReducer } from "react";
import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import SaveIcon from "@mui/icons-material/Save";
import Spinner from "common_components/PageLoader";
import { Failed, Success } from "common_components/toaster/toaster";
import { commonService } from "helpers/common.service";
import "./generalsettings.scss";
import { useDispatch } from 'react-redux';
import { setFramework, setSaveFrameworkVersion, setSaveFrameworkVersionCmp, setLoader } from '../../store/action';


interface Option {
  value: string;
  label: string;
}

interface AppState {
  getFramework: any[],
  saveGptVersion: string;
  saveEmbeddings: string;
  getVersion: any[];
  getTextEmbedded: any[];
  temperatureCompare: string;
  temperatureDatabase: string;
  temperatureDocument: string;
  selectedValue: any;
  generalSettings: any[];
  loader: boolean;
  saveGptVersionCmp: string;
  saveEmbeddingsCmp: string;
  temperatureCompareCmp: string;
  temperatureDatabaseCmp: string;
  temperatureDocumentCmp: string;
  selectedOption: Option | null;
  saveFrameworkVersion: string;
  saveFrameworkVersionCmp: string;
}
const options: Option[] = [
  { value: 'long_chain', label: 'Long Chain' },
  { value: 'llama', label: 'Llama ' },
];
const initialState: AppState = {
  getFramework: [],
  saveGptVersion: "",
  saveFrameworkVersion: "",
  saveEmbeddings: "",
  getVersion: [],
  getTextEmbedded: [],
  temperatureCompare: "",
  temperatureDatabase: "",
  temperatureDocument: "",
  selectedValue: undefined,
  generalSettings: [],
  loader: false,
  saveGptVersionCmp: "",
  saveFrameworkVersionCmp: "",
  saveEmbeddingsCmp: "",
  temperatureCompareCmp: "",
  temperatureDatabaseCmp: "",
  temperatureDocumentCmp: "",
  selectedOption: null, // Initialize selectedOption as null
};

const reducer = (state: any, action: any) => {
  switch (action.type) {
    case "SET_SAVE_GPT_VERSION":
      return { ...state, saveGptVersion: action.payload };
    case "SET_SAVE_FRAMEWORK_VERSION":
      return { ...state, saveFrameworkVersion: action.payload };
    case "SET_SAVE_EMBEDDINGS":
      return { ...state, saveEmbeddings: action.payload };
    case "SET_VERSION":
      return { ...state, getVersion: action.payload };
    case "SET_FRAMEWORK":
      return { ...state, getFramework: action.payload };
    case "SET_TEXT_EMBEDDED":
      return { ...state, getTextEmbedded: action.payload };
    case "SET_TEMPERATURE_COMPARE":
      return { ...state, temperatureCompare: action.payload };
    case "SET_TEMPERATURE_DATABASE":
      return { ...state, temperatureDatabase: action.payload };
    case "SET_TEMPERATURE_DOCUMENT":
      return { ...state, temperatureDocument: action.payload };
    case "SET_SELECTED_VALUE":
      return { ...state, selectedValue: action.payload };
    case "SET_LOADER":
      return { ...state, loader: action.payload };
    case "SET_SAVE_GPT_VERSION_CMP":
      return { ...state, saveGptVersionCmp: action.payload };
    case "SET_SAVE_FRAMEWORK_VERSION_CMP":
      return { ...state, saveFrameworkVersionCmp: action.payload };
    case "SET_SAVE_EMBEDDINGS_CMP":
      return { ...state, saveEmbeddingsCmp: action.payload };
    case "SET_TEMPERATURE_COMPARE_CMP":
      return { ...state, temperatureCompareCmp: action.payload };
    case "SET_TEMPERATURE_DATABASE_CMP":
      return { ...state, temperatureDatabaseCmp: action.payload };
    case "SET_TEMPERATURE_DOCUMENT_CMP":
      return { ...state, temperatureDocumentCmp: action.payload };
    case 'SELECT_OPTION':
      return { ...state, selectedOption: action.payload };
    default:
      return state;
  }
};

export default function General() {
  const [state, dispatch] = useReducer(reducer, initialState);
  const dispatchGlobal = useDispatch();
  useEffect(() => {
    getChatGPTModel();
    getTemperature();
    getEmbedded();
    getFrameWorkModel();
  }, []);


  const updateProjectFilter = (val: any) => {
    dispatch({ type: "SET_SAVE_GPT_VERSION", payload: val.value });
  };

  const updateEmbeddings = (val: any) => {
    dispatch({ type: "SET_SAVE_EMBEDDINGS", payload: val.value });
  };

  const getChatGPTModel = async () => {
    try {
      dispatch({ type: "SET_LOADER", payload: true });
      const url = "/get-gpt-models";
      const getChatGptVersion = await commonService.getServices(url);
      const data = getChatGptVersion?.data?.data;

      dispatch({ type: "SET_VERSION", payload: data });

      const compareActive = data.find(
        (x: any) => x?.gpt_model_name === getChatGptVersion?.data?.active
      );

      dispatch({ type: "SET_SAVE_GPT_VERSION", payload: compareActive?.id });
      dispatch({ type: "SET_SAVE_GPT_VERSION_CMP", payload: compareActive?.id });
      dispatch({ type: "SET_LOADER", payload: false });
    } catch (error: any) {
      dispatch({ type: "SET_LOADER", payload: false });
      Failed(error?.response?.data?.message);
    }
  };
  const getFrameWorkModel = async () => {
    try {
      dispatch({ type: "SET_LOADER", payload: true });
      const url = "/get-models";
      const response = await commonService.getServices(url);
      const data = response?.data?.data;
      const activeModelName = response?.data?.active;
      dispatch({ type: "SET_FRAMEWORK", payload: data });
      dispatchGlobal(setFramework(activeModelName));
      const matchedModel = data.find(
        (x: any) => x?.models === activeModelName
      );
      if (matchedModel) {
        dispatch({ type: "SET_SAVE_FRAMEWORK_VERSION", payload: matchedModel.id });
        dispatch({ type: "SET_SAVE_FRAMEWORK_VERSION_CMP", payload: matchedModel.id });
      } else {
        console.warn('No matching model found');
      }
      dispatch({ type: "SET_LOADER", payload: false });
    } catch (error: any) {
      dispatch({ type: "SET_LOADER", payload: false });
      console.error('Error:', error);
      Failed(error?.response?.data?.message || 'An error occurred');
    }
  };

  const getEmbedded = async () => {
    try {
      dispatch({ type: "SET_LOADER", payload: true });
      const url = "/get-embeddings";
      const gettextEm = await commonService.getServices(url);
      const data = gettextEm?.data?.data;

      dispatch({ type: "SET_TEXT_EMBEDDED", payload: data });

      const compareActive = data.find(
        (x: any) => x?.embeddings === gettextEm?.data?.active
      );

      dispatch({ type: "SET_SAVE_EMBEDDINGS", payload: compareActive?.id });
      dispatch({ type: "SET_SAVE_EMBEDDINGS_CMP", payload: compareActive?.id });
      dispatch({ type: "SET_LOADER", payload: false });
    } catch (error: any) {
      Failed(error?.response?.data?.message);
      dispatch({ type: "SET_LOADER", payload: false });
    }
  };

  const getTemperature = async () => {
    try {
      dispatch({ type: "SET_LOADER", payload: true });
      const url = "/get-temperature";
      const getTemperatureV = await commonService.getServices(url);
      const data = getTemperatureV?.data?.data[0];

      dispatch({ type: "SET_TEMPERATURE_DATABASE", payload: data?.database_temperature });
      dispatch({ type: "SET_TEMPERATURE_DATABASE_CMP", payload: data?.database_temperature });
      dispatch({ type: "SET_TEMPERATURE_DOCUMENT", payload: data?.document_temperature });
      dispatch({ type: "SET_TEMPERATURE_DOCUMENT_CMP", payload: data?.document_temperature });
      dispatch({ type: "SET_TEMPERATURE_COMPARE", payload: data?.comparison_temperature });
      dispatch({ type: "SET_TEMPERATURE_COMPARE_CMP", payload: data?.comparison_temperature });
      dispatch({ type: "SET_LOADER", payload: false });
    } catch (error: any) {
      Failed(error?.response?.data?.error);
      dispatch({ type: "SET_LOADER", payload: false });
    }
  };

  const saveChatGptSettings = async (url: any, name: any, dipCheck: any) => {
    try {
      dispatch({ type: "SET_LOADER", payload: true });
      // const url = "/set-gpt-model";
      const saveChatOpenAi = await commonService.putService(url, { model_name: name });

      if (saveChatOpenAi.status === 200 && dipCheck === "saveGptNode") {
        Success(saveChatOpenAi?.data?.message);
        getChatGPTModel();
        dispatch({ type: "SET_LOADER", payload: false });
      }
      if (saveChatOpenAi.status === 200 && dipCheck === "saveEmbeddingsNode") {
        getEmbedded();
        Success(saveChatOpenAi?.data?.message);
        dispatch({ type: "SET_LOADER", payload: false });
      }
      if (saveChatOpenAi.status === 200 && dipCheck === "saveFrameworkNode") {
        getFrameWorkModel();
        Success(saveChatOpenAi?.data?.message);
        dispatch({ type: "SET_LOADER", payload: false });
      }
    } catch (e: any) {
      Failed(e?.response?.data?.error);
      dispatch({ type: "SET_LOADER", payload: false });
    }
  };

  const saveEmbeddingsSettings = async (name: any) => {
    try {
      dispatch({ type: "SET_LOADER", payload: true });
      const url = "/set-embedding";
      const saveEmbeddingsAi = await commonService.putService(url, { embedding_name: name });

      if (saveEmbeddingsAi.status === 200) {
        Success(saveEmbeddingsAi?.data?.message);
        getEmbedded();
      }
    } catch (e: any) {
      Failed(e?.response?.data?.error);
      dispatch({ type: "SET_LOADER", payload: false });
    }
  };

  const saveTemperatureSettings = async (changedDatabase: any, changedDocument: any, changedCompare: any) => {
    try {
      dispatch({ type: "SET_LOADER", payload: true });
      const url = "/set-temperature";
      const saveTemperatureAi = await commonService.putService(url, {
        database_temperature: changedDatabase,
        document_temperature: changedDocument,
        comparison_temperature: changedCompare,
      });

      if (saveTemperatureAi.status === 200) {
        Success(saveTemperatureAi?.data?.message);
        getTemperature();
      }
    } catch (e: any) {
      Failed(e?.response?.data?.message);
      dispatch({ type: "SET_LOADER", payload: false });
    }
  };

  const saveSettings = () => {
    const saveGPT = state.saveGptVersion !== state.saveGptVersionCmp;
    const saveEmbeddingsCon = state.saveEmbeddings !== state.saveEmbeddingsCmp;
    const saveTempCon =
      state.temperatureDatabaseCmp !== state.temperatureDatabase ||
      state.temperatureDocumentCmp !== state.temperatureDocument ||
      state.temperatureCompareCmp !== state.temperatureCompare;
    const saveFramework = state.saveFrameworkVersion !== state.saveFrameworkVersionCmp
    if (saveGPT) {
      const compareActive = state.getVersion.find((x: any) => state.saveGptVersion === x?.id);
      const saveGptNode: string = 'saveGptNode';
      saveChatGptSettings("/set-gpt-model", compareActive?.gpt_model_name, saveGptNode);
    }

    if (saveEmbeddingsCon) {
      const compareActive = state.getTextEmbedded.find((x: any) => state.saveEmbeddings === x?.id);
      // saveEmbeddingsSettings(compareActive?.embeddings);
      const saveEmbeddingsNode: string = 'saveEmbeddingsNode';
      saveChatGptSettings("/set-embedding", compareActive?.embeddings, saveEmbeddingsNode)
    }

    if (saveTempCon) {
      saveTemperatureSettings(
        state.temperatureDatabase,
        state.temperatureDocument,
        state.temperatureCompare
      );
    }

    if (saveFramework) {
      const compareActive = state.getFramework.find((x: any) => state.saveFrameworkVersion === x?.id);
      const saveFrameworkNode: string = 'saveFrameworkNode';
      saveChatGptSettings("/set-model", compareActive?.models, saveFrameworkNode)
    }


    if (!saveGPT && !saveTempCon && !saveEmbeddingsCon && !saveFramework) {
      Failed("No settings have changed. Please change any settings.");
    }
  };

  const updateFrameWork = (val: any) => {
    dispatch({ type: "SET_SAVE_FRAMEWORK_VERSION", payload: val.value });
  };

  return (
    <Box>
      <Grid container spacing={3}>
        <Grid item xs={3}>
          <InputLabel sx={{ fontWeight: "400", color: "#333333", fontSize: "16px" }} htmlFor="chatGptVersion">
            ChatGpt Version
          </InputLabel>
          <FormControl sx={{ width: "100%", marginTop: "10px" }}>
            <Select
              className="select-custom-style1"
              labelId="chatGptVersion"
              id="chatGptVersion"
              onChange={(e) => updateProjectFilter(e.target)}
              value={state.saveGptVersion}
              IconComponent={KeyboardArrowDownIcon}
              sx={{
                ".MuiOutlinedInput-notchedOutline": {
                  border: "none",
                },
                "&.MuiSelect-select": {
                  borderRadius: "8px",
                  border: "0.5px solid #000",
                  height: "2em",
                },
                ".MuiOutlinedInput-input": {
                  background: "#FFFFFF",

                },
                borderRadius: "15px",
              }}
            >
              {state?.getVersion?.map((p: any, ind: any) => (
                <MenuItem key={ind} value={p.id}>
                  {p.gpt_model_name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={3}>
          <InputLabel sx={{ fontWeight: "400", color: "#333333", fontSize: "16px" }} htmlFor="frameWorks">
            Frameworks
          </InputLabel>
          <FormControl sx={{ width: "100%", marginTop: "10px" }}>
            <Select
              className="select-custom-style1"
              labelId="frameWorks"
              id="frameWorks"
              onChange={(e) => updateFrameWork(e?.target)}
              value={state.saveFrameworkVersion}
              IconComponent={KeyboardArrowDownIcon}
              sx={{
                ".MuiOutlinedInput-notchedOutline": {
                  border: "none",
                },
                "&.MuiSelect-select": {
                  borderRadius: "8px",
                  border: "0.5px solid #000",
                  height: "2em",
                },
                ".MuiOutlinedInput-input": {
                  background: "#FFFFFF",

                },
                borderRadius: "15px",
              }}
            >
              {state?.getFramework?.map((p: any, ind: any) => (
                <MenuItem key={ind} value={p.id}>
                  {p.models}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={3}>
          <InputLabel sx={{ fontWeight: "400", color: "#333333", fontSize: "16px" }} htmlFor="textEmbeddings">
            Text Embeddings
          </InputLabel>
          <FormControl sx={{ width: "100%", marginTop: "10px" }}>
            <Select
              className="select-custom-style2"
              labelId="textEmbeddings"
              id="textEmbeddings"
              onChange={(e) => updateEmbeddings(e.target)}
              value={state.saveEmbeddings}
              IconComponent={KeyboardArrowDownIcon}
              sx={{
                ".MuiOutlinedInput-notchedOutline": {
                  border: "none",
                },
                "&.MuiSelect-select": {
                  borderRadius: "8px",
                  border: "0.5px solid #000",
                },
                ".MuiOutlinedInput-input": {
                  background: "#FFFFFF",
                  height: "2em",
                },
                borderRadius: "16px",
              }}
            >
              {state?.getTextEmbedded?.map((p: any, ind: any) => (
                <MenuItem key={ind} value={p.id}>
                  {p.embeddings}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={3}>
          <InputLabel
            sx={{ fontWeight: "400", color: "#333333", fontSize: "16px" }}
            htmlFor="temperatureCompare"
          >
            Comparison Temperature
          </InputLabel>
          <FormControl sx={{ width: "100%", marginTop: "10px" }}>
            <TextField
              id="temperatureCompare"
              variant="outlined"
              placeholder="Enter temperature"
              value={state.temperatureCompare}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                const value = e.target.value;
                if (/^\d*\.?\d*$/.test(value)) {
                  dispatch({ type: "SET_TEMPERATURE_COMPARE", payload: value });
                }
              }}
              inputProps={{
                inputMode: "decimal",
                pattern: "[0-9]*[.,]?[0-9]*",
              }}
              sx={{
                ".MuiOutlinedInput-notchedOutline": {
                  borderRadius: "5px",
                  borderWidth: "0",
                },
                ".MuiOutlinedInput-root": {
                  borderRadius: "5px",
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    boxShadow: "0 0 5px 0.1px #0400ffc4",
                  },
                },
                ".MuiOutlinedInput-input": {
                  height: "1.75em",
                  fontSize: "16px",
                  backgroundColor: "#FFF",
                  borderRadius: "5px",
                  border: "black 1px solid",
                },
              }}
            />
          </FormControl>
        </Grid>
        <Grid item xs={3}>
          <InputLabel sx={{ fontWeight: "400", color: "#333333", fontSize: "16px" }} htmlFor="temperatureDatabase">
            Database Temperature
          </InputLabel>
          <FormControl sx={{ width: "100%", marginTop: "10px" }}>
            <TextField
              id="temperatureDatabase"
              variant="outlined"
              placeholder="Enter temperature"
              value={state.temperatureDatabase}

              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                const value = e.target.value;
                if (/^\d*\.?\d*$/.test(value)) {
                  dispatch({ type: "SET_TEMPERATURE_DATABASE", payload: e.target.value });
                }
              }}
              inputProps={{
                inputMode: "decimal",
                pattern: "[0-9]*[.,]?[0-9]*",
              }}
              sx={{
                ".MuiOutlinedInput-notchedOutline": {
                  borderRadius: "5px",
                  borderWidth: "0",
                },
                ".MuiOutlinedInput-root": {
                  borderRadius: "5px",
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    boxShadow: "0 0 5px 0.1px #0400ffc4",
                  },
                },
                ".MuiOutlinedInput-input": {
                  height: "1.75em",
                  fontSize: "16px",
                  backgroundColor: "#FFF",
                  borderRadius: "5px",
                  border: "black 1px solid",
                },

              }}
            />
          </FormControl>
        </Grid>
        <Grid item xs={3}>
          <InputLabel sx={{ fontWeight: "400", color: "#333333", fontSize: "16px" }} htmlFor="temperatureDocument">
            Document Temperature
          </InputLabel>
          <FormControl sx={{ width: "100%", marginTop: "10px" }}>
            <TextField
              id="temperatureDocument"
              variant="outlined"
              placeholder="Enter temperature"
              value={state.temperatureDocument}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                const value = e.target.value;
                if (/^\d*\.?\d*$/.test(value)) {
                  dispatch({ type: "SET_TEMPERATURE_DOCUMENT", payload: e.target.value });
                }
              }}
              inputProps={{
                inputMode: "decimal",
                pattern: "[0-9]*[.,]?[0-9]*",
              }}
              sx={{
                ".MuiOutlinedInput-notchedOutline": {
                  borderRadius: "5px",
                  borderWidth: "0",
                },
                ".MuiOutlinedInput-root": {
                  borderRadius: "5px",
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    boxShadow: "0 0 5px 0.1px #0400ffc4",
                  },
                },
                ".MuiOutlinedInput-input": {
                  height: "1.75em",
                  fontSize: "16px",
                  backgroundColor: "#FFF",
                  borderRadius: "5px",
                  border: "black 1px solid",
                },

              }}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <Button
            onClick={saveSettings}
            sx={{
              padding: "14px 37px",
              color: "White",
              border: "0",
              background: "#0e0aed",
              "&:hover": { background: "white", color: "black" },
              borderRadius: "8px",
              marginTop: "20px",
            }}
          >
            <SaveIcon sx={{ marginRight: "10px" }} />
            Save
          </Button>
        </Grid>
      </Grid>
      {state.loader && <Spinner />}
    </Box>
  );
}

