// src/redux/actions.ts
import { SET_FRAMEWORK, SET_SAVE_FRAMEWORK_VERSION, SET_SAVE_FRAMEWORK_VERSION_CMP, SET_LOADER } from './actionTypes';

export const setFramework = (data: string) => ({
  type: SET_FRAMEWORK,
  payload: data,
});

export const setSaveFrameworkVersion = (id: string) => ({
  type: SET_SAVE_FRAMEWORK_VERSION,
  payload: id,
});

export const setSaveFrameworkVersionCmp = (id: string) => ({
  type: SET_SAVE_FRAMEWORK_VERSION_CMP,
  payload: id,
});

export const setLoader = (isLoading: boolean) => ({
  type: SET_LOADER,
  payload: isLoading,
});
