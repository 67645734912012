import React, { useCallback, useRef, useState } from "react";
import "./AskQuestion.scss";
import {
  Box,
  Button,
  FormControl,
  IconButton,
  TextField,
  useTheme,
} from "@mui/material";
import { commonService } from "helpers/common.service";
import TypingLine from "./TypingLine";
import AgiraLogo from "asset/images/agirasurelogo.png";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { useReactToPrint } from "react-to-print";
import Spinner from "common_components/PageLoader";

const AskQuestion_chatPdf = () => {
  const [content, setContent] = useState<any>([]);
  const [text, setText] = useState<string>("");
  const [err, setErr] = useState<any>();
  const [loader, setLoader] = useState<boolean>(false);
  const [error, setError] = useState(false);
  const [helperText, setHelperText] = useState("");
  const [disablesubmit, setdisableSubmit] = useState(true);
  const ref = useRef<HTMLDivElement>(null);

  const theme = useTheme();
  const componentRef = useRef<any>();
  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    if (error) {
      return;
    }
    setText("");
    try {
      // Store the current question in the content state
      setContent((prevContent: any) => [
        ...prevContent,
        { question: text, answer: "" },
      ]);

      // Make the API request
      const response = await commonService.postServiceSeperateBaseUrl("/chat", {
        question: text,
        token: commonService.chatToken
      });
  
      // if (response && response?.data && response?.data?.data) {
        if (response && response?.data) {
        // const message = response?.data?.data?.join("");
        const message = response?.data;

        setContent((prevContent: any) =>
          prevContent?.map((item: any) =>
            item.question === text ? { ...item, answer: message.data?.airesponse } : item
          )
        );
      } else {
        console.error("Invalid response format");
      }
   
    } catch (err:any) {

      let msg = err.response?.data?.error;
      if(msg === `I'm sorry, but I couldn't find any relevant information for your question.`){
        setContent((prevContent: any) =>
        prevContent?.map((item: any) =>
          item.question === text ? { ...item, answer: msg } : item
        )
      );
      }
      setErr("Oops! Something Went Wrong");
    }
  };
  const onBeforeGetContentResolve = React.useRef<any>(null);
  React.useEffect(() => {
    if (typeof onBeforeGetContentResolve.current === "function") {
      onBeforeGetContentResolve.current();
    }
  }, [onBeforeGetContentResolve.current]);
  const handleOnBeforeGetContent = React.useCallback(() => {

    setLoader(true);

    // setText("Loading new text...");

    return new Promise((resolve: any) => {
      onBeforeGetContentResolve.current = resolve;

      setTimeout(() => {
        setLoader(false);
        // setText("New, Updated Text!");
        resolve();
      }, 2000);
    });
  }, [setLoader]);
  const handlePrintError = useCallback((e: any) => {

  }, []);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    copyStyles: true,
    documentTitle: "title",
    suppressErrors: true,
    onBeforeGetContent: handleOnBeforeGetContent,
    onPrintError: handlePrintError,
  });
  const handleTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    setText(inputValue);
    // Split the input into words and count the number of words
    const wordCount = inputValue
      .split(/\s+/)
      .filter((word) => word !== "").length;
    // Validate that there are at least 4 words
    if (wordCount < 3) {
      setdisableSubmit(false);
      setError(true);
      setHelperText("Please enter at least 3 words.");
    } else {
      setdisableSubmit(true);

      setError(false);
      setHelperText("");
    }
  };

  React.useEffect(() => {
    if (content.length) {
      ref.current?.scrollIntoView({
        behavior: "smooth",
        block: "end",
      });
    }
  }, [content.length]);
  return (
    <Box className="askquestionContainer">
      {loader && <Spinner />}

      {content.length <= 0 ? (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "70vh",
            margin: 0,
            fontSize: "30px",
            fontWeight: 700,
            opacity: 0.5,
          }}
        >
          {/* <img src={AgiraLogo} alt="agiralogo" width="60px" height="60px" /> */}
          How can I help today?
        </Box>
      ) : (
        <Button
          sx={{
            background: theme.palette.primary.main,
            color: "white",
            "&:hover": {
              background: theme.palette.secondary.light,
            },
            position: "absolute",
            right: "2rem",
            marginTop: "1rem",
          }}
          onClick={handlePrint}
        >
          Export
        </Button>
      )}

      <Box ref={componentRef}>
        <Box className="chatContent">
          {content?.map((line: any, index: number) => (
            <TypingLine
              key={index}
              question={line.question}
              fact={line.answer}
            />
          ))}
          <div ref={ref} />
        </Box>
      </Box>
      <FormControl
        onSubmit={(e) => handleSubmit(e)}
        component={"form"}
        sx={{
          position: "absolute",
          bottom: "20px",
          display: "flex",
          justifyContent: "center",
          width: "80%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            marginLeft: "40px",
            gap: "10px",
          }}
        >
          <TextField
            id="name"
            onChange={handleTextChange}
            value={text}
            placeholder="Ask a Question"
            fullWidth
            autoComplete="off"
            error={error}
            // helperText={helperText}
          />
          <Button
            type="submit"
            sx={{
              background:
                text && disablesubmit ? theme?.palette?.primary?.main : "grey",
              color: "white",
              // width: "6rem",
              // marginLeft: "10px",
              "&:hover": {
                background: theme?.palette?.primary?.main,
              },
            }}
            disabled={text && disablesubmit ? false : true}
          >
            <ArrowUpwardIcon />
          </Button>

          <Button
            sx={{
              background: theme.palette.primary.main,
              color: "white",
              "&:hover": {
                background: theme.palette.secondary.light,
              },
            }}
            onClick={() => {
              setContent([]);
              setError(false);
              setHelperText("");
            }}
          >
            Clear
          </Button>
        </Box>
        <p style={{ marginLeft: "40px", color: "red" }}>{helperText}</p>
      </FormControl>
    </Box>
  );
};

export default AskQuestion_chatPdf;
