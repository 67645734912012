import React, { useState, useEffect } from "react";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Button,
    Chip,
} from "@mui/material";
import { Failed, Success } from "common_components/toaster/toaster";
import { commonService } from "helpers/common.service";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import DownloadIcon from "@mui/icons-material/Download";
import DeleteIcon from "@mui/icons-material/Delete";
import { useLocation } from "react-router-dom";
import Loading from "../../common_components/PageLoader/index";
import { Box } from "@mui/system";
import HistoryIcon from '@mui/icons-material/History';

const SideSuggestions = (props: any) => {
    const [rows, setRows] = useState<any>([]);
    const [serviceCall, setServiceCall] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    const { policyNo } = props;
    React.useEffect(() => {
        getViewDocument(policyNo);
    }, []);

    const getViewDocument = async (policyNo: any) => {
        setLoading(true);
        try {
            const fileDownload = await commonService.getServices(`/file-claim/${policyNo}`);

            setRows(fileDownload?.data?.data);
            setLoading(false);
        } catch (error: any) {
            Failed(error?.response?.data?.message);
            setLoading(false);
        }
    };

    const handleView = async (id: any) => {
        setLoading(true);
        try {
            const fileDownload = await commonService.postService("/get-signed-url", {
                file_id: id,
            });
            window.open(fileDownload?.data?.message, "_blank");
            setLoading(false);
        } catch (error: any) {
            Failed(error?.response?.data?.message);
            setLoading(false);
        }
    };

    const handleDelete = async (id: any) => {
        setLoading(true);
        try {
            const fileDelete = await commonService.postService("/delete", {
                identifier: id,
            });
            if (fileDelete) {
                Success("Delete Successfully");
                setServiceCall(true);
                setLoading(false);
            }
        } catch (error: any) {
            Failed(error?.response?.data?.message);
            setLoading(false);
        }
    };

    const handleDownload = async (id: any, fileName: string) => {
        setLoading(true);
        try {
            const response = await commonService.pdfDownloadService("/get-signed-url", { file_id: id });
            const blob = new Blob([response.data], { type: "application/pdf" });
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", fileName);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);
        } catch (error: any) {
            Failed(error?.response?.data?.message);
            setLoading(false);
        } finally {
            setLoading(false);
        }
    };
    const goBack = () => {
        window.history.back();
    };
    return (
        <>
            <Box sx={{
                width: "17%",
                /* min-height: 0%; */
                height: "35vh",
                position: "absolute",
                overflowY: "auto",
                top: "45%",
                right: "1%",
                borderRadius: "16px",

            }}>
               

                {rows.length > 0 && rows && (
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>

                                    <TableCell>Doc Type</TableCell>
                                    <TableCell>Status</TableCell>

                                    <TableCell>Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rows.map((row: any) => (
                                    <TableRow key={row?.id}>


                                        <TableCell>{row?.category}</TableCell>
                                        <TableCell>
                                            <Chip
                                                sx={{
                                                    width: "27%",
                                                    height: "11px",
                                                    bordeRadius: "22px"
                                                }}
                                                color={
                                                    row?.status === "Pending"
                                                        ? "info"
                                                        : row?.status === "Failed"
                                                            ? "error"
                                                            : "success"
                                                }
                                            />
                                        </TableCell>
                                        <TableCell
                                            sx={{
                                                display: "flex",
                                            }}
                                        >
                                           
                                            <Button onClick={() => handleDownload(row?.file_id, row?.file_name)}>
                                                <DownloadIcon />
                                            </Button>
                                            
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                )}
               
            </Box>
        </>
    );
};

export default SideSuggestions;
