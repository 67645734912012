import React, { ChangeEvent, useCallback, useEffect, useRef, useState } from "react";
import "./AskQuestion.scss";
import { Box, Button, TextField } from "@mui/material";

import Spinner from "common_components/PageLoader";

import UpdateIcon from "@mui/icons-material/Update";
import SendIcon from "@mui/icons-material/Send";

import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { useNavigate } from "react-router-dom";
import { textAlign } from "@mui/system";
import { Chat } from "@mui/icons-material";
import SendIconL from "asset/images/Group.svg";
import { commonService } from "helpers/common.service";
import { Failed } from "common_components/toaster/toaster";

const ClaimInquiryVerify = () => {
  const [text, setText] = useState<string>("");
  const [loader, setLoader] = useState<boolean>(false);
  const [activeFrameWork, setActiveFrameWork] = useState('');
  const navigate = useNavigate();
  const handleTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    if (/^[0-9]*$/.test(inputValue)) {
      setText(inputValue);
    }
  };
  const backNavigate = () => {
    navigate("/user/ask_anything");
  };
  useEffect(() => {
    getFrameWorkModel();
  }, []);

  const getFrameWorkModel = async () => {
    try {
      const url = "/get-models";
      const response = await commonService.getServices(url);
      const activeModelName = response?.data?.active;
      setActiveFrameWork(activeModelName);
    } catch (error: any) {
      console.error('Error:', error);
      Failed(error?.response?.data?.message || 'An error occurred');
    }
  };
  return (
    <Box className="askquestionContainer">
      {loader && <Spinner />}

      <Box sx={{ minWidth: 277, margin: "0% 14%" }}>
        <div
          style={{
            display: "flex",
            marginBottom: "143px",
            color: "#5553CF",
            alignItems: "center",
          }}
        >
          <KeyboardArrowLeftIcon
            sx={{
              color: "black",
              width: "37px",
              height: "28px",
              cursor: "pointer",
            }}
            onClick={backNavigate}
          />
          <h1 style={{ fontSize: "40px" }}>Search data against a claim</h1>

          {/* <Button
            variant="outlined"
            sx={{
              color: "Black",
              backgroundColor: "#FFF",
              border: "0",
              borderRadius: "7px",
              display: "flex",
              height: "3rem",
              alignItems: "center",
            }}
          >
            <span>
              <UpdateIcon />
            </span>
            <span>Chat History</span>
          </Button> */}
        </div>
      </Box>

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "35vh",
          margin: 0,
          fontSize: "30px",
          fontWeight: 700,
        }}
      >
        <Box sx={{ minWidth: 277, margin: "0% 14%" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <p
              style={{
                fontSize: "1.5rem",
                fontWeight: "700",
                paddingLeft: "20px",
              }}
            >
              {" "}
              Please enter a Claim number to begin
            </p>

            <Box sx={{ width: "60%" }}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  position: "relative",
                }}
              >
                <TextField
                  fullWidth
                  id="claimEnquiry"
                  onChange={handleTextChange}
                  value={text}
                  //   {...register("email")}
                  //   error={!!errors.email}
                  //   helperText={errors.email?.message}
                  sx={{
                    ".MuiOutlinedInput-notchedOutline": {
                      borderRadius: "10px",
                      borderWidth: "0",
                    },
                    ".MuiOutlinedInput-root": {
                      borderRadius: "10px",
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        boxShadow: "0 0 5px 0.1px #0400ffc4",
                      },
                    },
                    ".MuiOutlinedInput-input": {
                      height: "2em",
                      fontSize: "16px",
                      backgroundColor: "#FFF",
                      borderRadius: "10px",
                      border: "black 1px solid",
                    },
                    marginTop: "10px",
                  }}
                />

                {text && (
                  <div
                    onClick={() =>  
                      navigate("/user/ask_anything_Inquiry", {
                        state: { yourKey: text, activeFrameWork: activeFrameWork },
                      })
                    }
                    style={{
                      position: "absolute",
                      right: "8px",
                      color: "#5553CF",
                      bottom: "15px",
                      cursor: "pointer",
                    }}
                  >
                    {" "}
                    <img
                      src={SendIconL}
                      alt="agiraLogo"
                      width="30px"
                      height="30px"
                    />
                  </div>

                  // <SendIcon

                  //   sx={{
                  //     position: "absolute",
                  //     right: "8px",
                  //     color: "#5553CF",
                  //     bottom: "19px",
                  //     cursor: "pointer",
                  //   }}
                  // />
                )}
              </Box>
            </Box>
            <p
              style={{
                fontSize: "18px",
                fontWeight: "400",
                opacity: "0.7",
              }}
            >
              Don’t know? Try finding your claim number <a>here</a>
            </p>
          </div>
          {/* <ChatApp/> */}
        </Box>
      </Box>
    </Box>
  );
};

export default ClaimInquiryVerify;
