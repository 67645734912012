import React, { useEffect, useCallback, useRef, useState } from "react";
import "./AskQuestion.scss";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Chip,
  FormControl,
  Grid,
  Skeleton,
  TextField,
  Typography,
} from "@mui/material";
import { commonService } from "helpers/common.service";
import TypingLine from "./TypingLine";
import { useReactToPrint } from "react-to-print";
import Spinner from "common_components/PageLoader";
import Modal from "@mui/material/Modal";
import { useTheme } from "@mui/material/styles";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import AgiraLogo from "asset/images/agirasurelogo.png";
import SendIconL from "asset/images/Group.svg";
import "./Chat.scss";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import LikeDislike from "./LikeDislike";
import MicIcon from '@mui/icons-material/Mic';
import MicOffIcon from '@mui/icons-material/MicOff';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import { useSelector } from "react-redux";
const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  // border: '2px solid #000',
  boxShadow: 24,
  pt: 4,
  px: 4,
  pb: 3,
};

interface Message {
  sender: "user" | "ai";
  text: string;
}

const PolicyAssistant = () => {
  const [content, setContent] = useState<any>([]);
  const [text, setText] = useState<string>("");
  const [err, setErr] = useState<any>();
  const [loader, setLoader] = useState<boolean>(false);
  const [loaderType, setLoaderType] = useState<boolean>(false);
  const [error, setError] = useState(false);
  const [claimNoerror, setClaimNoError] = useState<any>(false);

  const [helperText, setHelperText] = useState("");
  const [modalhelperText, setModalHelperText] = useState("");

  const [disablesubmit, setdisableSubmit] = useState(true);
  const ref = useRef<HTMLDivElement>(null);
  const [alignment, setAlignment] = useState<any>("agent_session");
  const [modal, setModal] = useState<boolean>(false);
  const [open, setOpen] = React.useState(false);
  const [claimNo, setClaimno] = useState<any>();
  const [addclaimNo, setAddClaimno] = useState<any>();
  const [search, setSearch] = useState("");
  const [projectFilter, setProjectFilter] = useState<string>("");
  const [allProjects, setAllProjects] = useState([]);
  const [selectedProjects, setSelectedProjects] = useState<string[]>([]);
  const [project, setProject] = useState<any>([]);
  const [generalSettings, setGeneralSettings] = useState<any>([]);
  const [dropDown, setDropDown] = useState([]);
  const [messages, setMessages] = useState<Message[]>([]);
  const [input, setInput] = useState<string>("");
  const navigate = useNavigate();
  const location = useLocation();
  const [activateSkeleton, setActivateSkeleton] = useState<boolean>(false);
  const { yourKey } = location?.state;
  const frameworkData = useSelector((state: any) => state.activeFramework.frameworkData);

  const handleClose = () => {
    setModal(false);
  };

  const theme = useTheme();
  const componentRef = useRef<any>();
  const chatBoxRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (chatBoxRef.current) {
      chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;
    }
  }, [messages]);

  const handleSendMessage = () => {
    // Your logic to send a message goes here

    // Scroll to the bottom after sending a message
    if (chatBoxRef.current) {
      chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;
    }
  };
  const sendMessage = async () => {
    setLoaderType(true);
    setActivateSkeleton(true);

    if (input.trim()) {
      const userMessage: Message = { sender: "user", text: input };
      setMessages((prevMessages) => [...prevMessages, userMessage]);

      try {
        setTimeout(() => {
          setInput("");
        }, 100);

        // Determine the correct URL endpoint based on activeFrame and frameworkData
        const urlPost =
          (yourKey === "Llama" && frameworkData !== "Longchain") ||
            frameworkData === "Llama"
            ? "/llama-enquire-claim"
            : "/chat_session";

        const requestBody = urlPost === "/llama-enquire-claim"
          ? { question: input }
          : {
            question: input,
            question_type: "database",
          };

        const response = await commonService.postServiceSeperateBaseUrl(
          urlPost,
          requestBody
        );

        const aiManipulate = response?.data?.data?.airesponse || response?.data?.data;
        const textWithBreaks = aiManipulate.replace(/\n/g, "<br />");
        const textWithStrong = textWithBreaks.replace(/\*\*(.*?)\*\*/g, "<strong>$1</strong>");

        if (textWithStrong) {
          setActivateSkeleton(false);
        }

        const aiMessage: Message = {
          sender: "ai",
          text: textWithStrong,
        };

        setMessages((prevMessages) => [...prevMessages, aiMessage]);
        setLoaderType(false);
        setInput("");
        resetTranscript();
        handleSendMessage();

      } catch (error: any) {
        console.error("Error sending message:", error.response?.data?.error || error.message);
        const aiMessage: Message = {
          sender: "ai",
          text: error?.response?.data?.error || "Check your internet connection",
        };
        if (error) {
          setActivateSkeleton(false);
        }
        setMessages((prevMessages) => [...prevMessages, aiMessage]);
        setLoaderType(false);
        setInput("");
        resetTranscript();
        handleSendMessage();
      }
    }
  };


  const handleChange = (e: any) => {
    setInput(e.target.value);
    const inputValue = e.target.value;
    setText(inputValue);
    // Split the input into words and count the number of words
    const wordCount = inputValue
      .split(/\s+/)
      .filter((word: any) => word !== "").length;
    // Validate that there are at least 4 words
    if (wordCount < 3) {
      setdisableSubmit(false);
      setError(true);
      setHelperText("Please enter at least 3 words.");
    } else {
      setdisableSubmit(true);

      setError(false);
      setHelperText("");
    }
    e.preventDefault();
  };

  const handleKeyDown = (e: any) => {
    if (e?.target?.value?.length > 10) {

      if (e.key === "Enter") {
        e.preventDefault();
        sendMessage();
      }
    } else {
      // handleChange(e);
    }

    if (e.key === "Backspace") {
      if (e.target.value.length < 10) {
        resetTranscript();
      }
    }
  };

  const onBeforeGetContentResolve = React.useRef<any>(null);
  React.useEffect(() => {
    if (typeof onBeforeGetContentResolve.current === "function") {
      onBeforeGetContentResolve.current();
    }
  }, [onBeforeGetContentResolve.current]);
  const handleOnBeforeGetContent = React.useCallback(() => {
    setLoader(true);

    // setText("Loading new text...");

    return new Promise((resolve: any) => {
      onBeforeGetContentResolve.current = resolve;

      setTimeout(() => {
        setLoader(false);
        // setText("New, Updated Text!");
        resolve();
      }, 2000);
    });
  }, [setLoader]);
  const handlePrintError = useCallback((e: any) => { }, []);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    copyStyles: true,
    documentTitle: "title",
    suppressErrors: true,
    onBeforeGetContent: handleOnBeforeGetContent,
    onPrintError: handlePrintError,
  });
  const handleTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    setText(inputValue);
    // Split the input into words and count the number of words
    const wordCount = inputValue
      .split(/\s+/)
      .filter((word) => word !== "").length;
    // Validate that there are at least 4 words
    if (wordCount < 3) {
      setdisableSubmit(false);
      setError(true);
      setHelperText("Please enter at least 3 words.");
    } else {
      setdisableSubmit(true);

      setError(false);
      setHelperText("");
    }
  };
  const handleAdd = () => {
    if (claimNo) {
      setAddClaimno(claimNo);
      setModal(false);
      setClaimNoError(false);
      setModalHelperText("");
    } else {
      setClaimNoError(true);
      setModalHelperText("*Please enter a claim Number");
    }
  };
  React.useEffect(() => {
    if (content.length) {
      ref.current?.scrollIntoView({
        behavior: "smooth",
        block: "end",
      });
    }
    // getGeneralSettings();
  }, [content.length]);

  const latestMessage = content[content.length - 1];
  const renderLatestMessage = latestMessage && (
    <TypingLine
      key={content.length - 1}
      question={latestMessage.question}
      fact={latestMessage.answer}
      // err={err}
      isLatest={true} // Since it's the latest message
    />
  );
  const backNavigate = () => {
    navigate("/user/ask_anything");
  };
  const questionInput = (staticText: any) => {
    setInput(staticText);
  };
  const {
    transcript,
    listening,
    resetTranscript,
    browserSupportsSpeechRecognition
  } = useSpeechRecognition();

  useEffect(() => {
    if (!listening) {
      console.log('Speech recognition service has stopped');

    }
    if (transcript) {
      setInput(transcript);
    }
  }, [listening, transcript]);

  if (!browserSupportsSpeechRecognition) {
    return <span>Browser doesn't support speech recognition.</span>;
  }

  useEffect(() => {
    setInput("");
    resetTranscript();
    SpeechRecognition.stopListening();
    return () => {
      // Cleanup function to stop listening when the component unmounts
      SpeechRecognition.stopListening();
    };
  }, []);

  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (input && inputRef.current) {
      inputRef.current.focus();
    }
  }, [input]);

  return (
    <Box className="askquestionContainer">

      {loader && <Spinner />}

      {messages.length <= 0 ? (
        !addclaimNo && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "70vh",
              margin: 0,
              fontSize: "30px",
              fontWeight: 700,
            }}
          >
            <Box sx={{ minWidth: 277, margin: "0% 22% 0% 14%" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "143px",
                  color: "#5553CF",
                  alignItems: "center",
                }}
              >
                <h2>
                  {" "}
                  <KeyboardArrowLeftIcon
                    sx={{
                      color: "black",
                      width: "37px",
                      height: "28px",
                      cursor: "pointer",
                    }}
                    onClick={backNavigate}
                  />
                  Explore your data
                </h2>
                {/* <Button
                  variant="outlined"
                  sx={{
                    color: "Black",
                    backgroundColor: "#FFF",
                    border: "0",
                    borderRadius: "7px",
                    display: "flex",
                    height: "3rem",
                    alignItems: "center",
                  }}
                >
                  <span>
                    <UpdateIcon />
                  </span>
                  <span>Chat History</span>
                </Button> */}
              </div>

              <p
                style={{
                  fontSize: "1.5rem",
                  fontWeight: "700",
                  paddingLeft: "20px",
                }}
              >
                {" "}
                Suggestions to begin
              </p>
              <div style={{ display: "flex", gap: "25px" }}>
                <Card
                  variant="outlined"
                  sx={{
                    backgroundColor: "white",
                    borderRadius: "25px",
                    border: "0",
                    width: "32%",
                    boxShadow: 1
                  }}
                >
                  <CardContent>
                    <Typography
                      sx={{ fontSize: 18, display: "flex" }}
                      gutterBottom
                    >
                      <span>
                        What is the average claim amount processed this year?
                      </span>
                    </Typography>
                  </CardContent>
                  <br></br> <br></br>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                  >
                    <CardActions>

                      <Button>

                        <img src={SendIconL} alt="agiraLogo" width="30px" height="30px" onClick={() =>
                          questionInput(
                            "What is the average claim amount processed this year?"
                          )
                        } />
                      </Button>
                    </CardActions>
                  </Box>
                </Card>
                <Card
                  variant="outlined"
                  sx={{
                    backgroundColor: "white",
                    borderRadius: "25px",
                    border: "0",
                    width: "32%",
                    boxShadow: 1
                  }}
                >
                  <CardContent>
                    <Typography
                      sx={{ fontSize: 18, display: "flex" }}
                      gutterBottom
                    >
                      <span>
                        Get me the age of Claimant named {"<Claimant Name>"}
                      </span>
                    </Typography>
                  </CardContent>
                  <br></br> <br></br>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                  >
                    <CardActions>

                      <Button>

                        <img src={SendIconL} alt="agiraLogo" width="30px" height="30px" onClick={() =>
                          questionInput(
                            "Get me the age of Claimant named <Claimant Name>"
                          )
                        } />
                      </Button>
                    </CardActions>
                  </Box>
                </Card>
                <Card
                  variant="outlined"
                  sx={{
                    backgroundColor: "white",
                    borderRadius: "25px",
                    border: "0",
                    width: "32%",
                    boxShadow: 1
                  }}
                >
                  <CardContent>
                    <Typography
                      sx={{ fontSize: 18, display: "flex" }}
                      gutterBottom
                    >
                      <span>
                        Injury details of Claim{"<Claim Number>"}
                      </span>
                    </Typography>
                  </CardContent>
                  <br></br> <br></br>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                  >
                    <CardActions>
                      <Button>
                        <img src={SendIconL} alt="agiraLogo" width="30px" height="30px" onClick={() =>
                          questionInput(
                            "Injury details of Claim <Claim Number>"
                          )
                        } />
                      </Button>
                    </CardActions>
                  </Box>
                </Card>
              </div>
            </Box>
          </Box>
        )
      ) : (
        <Button
          sx={{
            background: theme.palette.primary.main,
            color: "white",
            "&:hover": {
              background: theme.palette.secondary.light,
            },
            position: "absolute",
            right: "2rem",
            marginTop: "1rem",
            padding: "9px 44px",
            backgroundColor: "#5553cf",
            borderRadius: "13px"
          }}
          onClick={handlePrint}
        >
          Export
        </Button>
      )}
      {/* <div className={styles.chatContainer}>
        {messages.map((msg, index) => (
          <div
            key={index}
            className={`${styles.message} ${
              msg.sender === "user" ? styles.userMessage : styles.aiMessage
            }`}
          >
            <div className={styles.icon}>
              <FontAwesomeIcon
                icon={msg.sender === "user" ? faUser : faRobot}
              />
            </div>
            <div>
              <strong>{msg.sender === "user" ? "You" : "AI"}:</strong>{" "}
              <Typography
                sx={{
                  background: "white",
                  padding: "13px",
                  borderRadius: "19px",
                  width: "60%",
                  lineHeight: "27px",
                }}
              >
                {msg.text}
              </Typography>
            </div>
          </div>
        ))}
      </div> */}
      {messages.length > 0 && messages && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            height: "75vh",
            overflowY: "scroll",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",

              color: "#5553CF",
              alignItems: "center",
              margin: "0% 11.5%"
            }}
          >
            {" "}
            <KeyboardArrowLeftIcon
              sx={{
                color: "black",
                width: "37px",
                height: "28px",
                cursor: "pointer",
              }}
              onClick={backNavigate}
            />
            <p style={{

              fontWeight: "700",
              color: "#5553CF",
              fontSize: "40px"
            }}>Explore your data</p>
          </div>
          <div
            style={{ width: "72%", overflowY: "auto", scrollBehavior: "smooth", marginLeft: "16rem", marginBottom: "3rem", display: "flex", flexDirection: "column", backgroundColor: "white", borderRadius: "16px" }}
            ref={chatBoxRef}
          >
            {messages.map((msg, index) => (
              <div>
                <div
                  key={index}
                  style={{
                    display: "flex",
                    scrollBehavior: "smooth",
                    alignItems: "flex-start",
                    marginBottom: "0.5rem",
                    padding: "0.5rem",
                    borderRadius: "1rem",
                    backgroundColor: "white",
                    fontSize: "18px",
                    lineHeight: "29px",
                    fontWeight: msg.sender === "user" ? "600" : "400",
                    gap: "8px",
                    marginTop: msg.sender == "user" ? "1rem" : "0rem",
                  }}
                >
                  {msg?.sender === "user" ? (
                    <Avatar sx={{ width: 30, height: 30 }} />
                  ) : (
                    <img
                      src={AgiraLogo}
                      alt="agiraLogo"
                      width="30px"
                      height="30px"
                    />
                  )}
                  <div style={{ display: "flex", gap: "5px" }}>
                    <strong>{msg.sender === "user" ? "You:" : "Agira:"}</strong>

                    <div dangerouslySetInnerHTML={{ __html: msg?.text }} />

                  </div>
                </div>
                <div style={{ marginLeft: "1%" }}>
                  {msg.sender === "ai" && (<LikeDislike message={msg} allMessages={messages} chatSection={"Explore your data"} />)}
                </div>
              </div>
            ))}
            {activateSkeleton && (
              <div
                style={{
                  display: "flex",
                  background: "white",
                  width: "60%",
                  padding: "8px",
                  borderRadius: "15px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {" "}
                  <img
                    src={AgiraLogo}
                    alt="agiraLogo"
                    width="30px"
                    height="30px"
                  />
                  <strong
                    style={{
                      fontSize: "18px",
                    }}
                  >
                    Agira:{" "}
                  </strong>{" "}
                </div>

                <Box sx={{ width: 857, margin: "8px" }}>
                  <Skeleton
                    sx={{
                      display: 'block',
                      backgroundImage: 'linear-gradient(to right, grey, #5553cf)',
                      height: 'auto',
                      marginTop: 0,
                      marginBottom: 0,
                      transformOrigin: '0 55%',
                      WebkitTransform: 'scale(1, 0.60)',
                      MozTransform: 'scale(1, 0.60)',
                      MsTransform: 'scale(1, 0.60)',
                      transform: 'scale(1, 0.60)',
                      borderRadius: '4px / 6.7px',
                      WebkitAnimation: 'animation-c7515d 2s ease-in-out 0.5s infinite',
                      animation: 'animation-c7515d 2s ease-in-out 0.5s infinite',
                      fontSize: '1rem',
                      position: 'relative',
                      overflow: 'hidden',
                      '&::after': {
                        content: '""',
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        backgroundImage: 'linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0))',
                        animation: 'wave-animation 1.5s infinite',
                      },
                      '@keyframes wave-animation': {
                        '0%': {
                          transform: 'translateX(-100%)',
                        },
                        '100%': {
                          transform: 'translateX(100%)',
                        },
                      },
                    }}
                  />
                  <Skeleton animation="wave" sx={{
                    display: 'block',
                    backgroundImage: 'linear-gradient(to right, grey, #5553cf)',
                    height: 'auto',
                    marginTop: 0,
                    marginBottom: 0,
                    transformOrigin: '0 55%',
                    WebkitTransform: 'scale(1, 0.60)',
                    MozTransform: 'scale(1, 0.60)',
                    MsTransform: 'scale(1, 0.60)',
                    transform: 'scale(1, 0.60)',
                    borderRadius: '4px / 6.7px',
                    WebkitAnimation: 'animation-c7515d 4s ease-in-out 4.5s infinite',
                    animation: 'animation-c7515d 2s ease-in-out 0.5s infinite',
                    fontSize: '1rem',
                    position: 'relative',
                    overflow: 'hidden',
                    '&::after': {
                      content: '""',
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      width: '100%',
                      height: '100%',
                      backgroundImage: 'linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0))',
                      animation: 'wave-animation 2s infinite',
                    },
                    '@keyframes wave-animation': {
                      '0%': {
                        transform: 'translateX(-50%)',
                      },
                      '100%': {
                        transform: 'translateX(100%)',
                      },
                    },
                  }} />
                  <Skeleton width="60%" sx={{
                    display: 'block',
                    backgroundImage: 'linear-gradient(to right, grey, #5553cf)',
                    height: 'auto',
                    marginTop: 0,
                    marginBottom: 0,
                    transformOrigin: '0 55%',
                    WebkitTransform: 'scale(1, 0.60)',
                    MozTransform: 'scale(1, 0.60)',
                    MsTransform: 'scale(1, 0.60)',
                    transform: 'scale(1, 0.60)',
                    borderRadius: '4px / 6.7px',
                    WebkitAnimation: 'animation-c7515d 2s ease-in-out 0.5s infinite',
                    animation: 'animation-c7515d 2s ease-in-out 0.5s infinite',
                    fontSize: '1rem',
                    position: 'relative',
                    overflow: 'hidden',
                    '&::after': {
                      content: '""',
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      width: '100%',
                      height: '100%',
                      backgroundImage: 'linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0))',
                      animation: 'wave-animation 2.5s infinite',
                    },
                    '@keyframes wave-animation': {
                      '0%': {
                        transform: 'translateX(-75%)',
                      },
                      '100%': {
                        transform: 'translateX(100%)',
                      },
                    },
                  }} />
                </Box>
              </div>
            )}
            <div ref={ref} />
          </div>
        </div>
      )}

      {/* <Box ref={componentRef}>
        <Box className="chatContent">
          {messages
            .slice(0, content.length - 1)
            .map((line: any, index: number) => (
              <TypingLine
                key={index}
                question={line.question}
                fact={line.answer}
                err={err}
                isLatest={false} // Since it's not the latest message
              />
            ))}
          {renderLatestMessage}

          <div ref={ref} />
        </Box>
      </Box> */}
      {/* {addclaimNo && <h3 style={{textAlign:"center", position:"absolute", top:"80%", left:"50%", border:"1px solid black", padding:"10px"}}>Claim No: {addclaimNo}</h3>} */}
      {addclaimNo && (
        <Chip
          sx={{
            textAlign: "center",
            position: "absolute",
            top: "81.5%",
            left: "50%",
            fontSize: "15px",
            padding: "25px",
          }}
          label={`Claim No : ${addclaimNo}`}
          variant="outlined"
        />
      )}

      <FormControl
        // onSubmit={(e) => handleSubmit(e)}
        component={"form"}
        sx={{
          position: "absolute",
          bottom: "20px",
          display: "flex",
          justifyContent: "center",
          width: "80%",
        }}
      >
        <Grid item sx={{ display: "flex", alignItems: "center" }}></Grid>
        <Box
          sx={{
            display: "flex",
            margin: "0rem 10rem",
            gap: "10px",
          }}
        >
          <TextField
            id="name"
            type="text"
            value={input}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            placeholder="Ask a Question"
            fullWidth
            autoComplete="off"
            error={error}
            disabled={loaderType}
            inputRef={inputRef}
            sx={{
              ".MuiOutlinedInput-notchedOutline": {
                borderRadius: "15px",
                borderWidth: "0",
              },
              ".MuiOutlinedInput-root": {
                borderRadius: "15px",
              },
              ".MuiOutlinedInput-input": {
                height: "1.25em",
                fontSize: "20px",
                backgroundColor: "#FFF",
                borderRadius: "15px",
                padding: "15px 39px 17px 14px",
              },
            }}
          />
          {listening ? <Button
            // onClick={sendMessage}
            onClick={(e) => { e.preventDefault(); SpeechRecognition.stopListening() }}
            sx={{
              top: "10px",
              position: "absolute",
              left: "105px",
              color: "#5553CF",
              cursor: "pointer",
              // width: "6rem",
              // marginLeft: "10px",
            }}
            disabled={!loaderType ? false : true}
          >
            {!loaderType &&
              // <img src={SendIconL} alt="agiraLogo" width="30px" height="30px" />
              <MicIcon sx={{
                color: "black",
                width: "37px",
                height: "28px",
                cursor: "pointer",
              }} />


            }
          </Button> : <Button
            // onClick={sendMessage}
            onClick={(e) => { e.preventDefault(); SpeechRecognition.startListening({ continuous: true }) }}
            sx={{
              top: "10px",
              position: "absolute",
              left: "105px",
              color: "#5553CF",
              cursor: "pointer",
              // width: "6rem",
              // marginLeft: "10px",
            }}
            disabled={!loaderType ? false : true}
          >
            {!loaderType &&
              // <img src={SendIconL} alt="agiraLogo" width="30px" height="30px" />
              <MicOffIcon sx={{
                color: "black",
                width: "37px",
                height: "28px",
                cursor: "pointer",
              }} />
            }
          </Button>}
          <Button
            onClick={sendMessage}
            sx={{
              top: "8px",
              position: "absolute",
              right: "250px",
              color: "#5553CF",
              cursor: "pointer",
              // width: "6rem",
              // marginLeft: "10px",
              "&:hover": {
                background: "none", // Adjust the rgba value for desired transparency
              }
            }}
            disabled={!loaderType && input ? false : true}
          >

            <img src={SendIconL} alt="agiraLogo" width="30px" height="30px" />

          </Button>

          <Button
            sx={{
              width: "6%",
              borderRadius: "8px",
              padding: "0px 44px",
              background: theme.palette.primary.main,
              color: "white",
              "&:hover": {
                background: theme.palette.secondary.light,
              },
            }}
            onClick={() => {
              setError(false);
              setHelperText("");
              setInput("");
              resetTranscript();
            }}
            disabled={loaderType}
          >
            Clear
          </Button>
        </Box>
        <p style={{ marginLeft: "40px", color: "red" }}>{helperText}</p>
      </FormControl>

      <Modal
        open={modal}
        onClose={handleClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box sx={{ ...style, width: 400 }}>
          <Typography id="spring-modal-title" variant="h3" component="h2">
            Add Claim Number
          </Typography>
          &nbsp;&nbsp; &nbsp;{" "}
          <TextField
            id="name"
            onChange={(e: any) => setClaimno(e.target.value)}
            value={claimNo}
            placeholder="Enter the claim number"
            fullWidth
            autoComplete="off"
            error={claimNoerror}
          // helperText={helperText}
          />
          <p style={{ marginLeft: "40px", color: "red" }}>{modalhelperText}</p>
          <Button
            onClick={handleAdd}
            sx={{
              background: "green",
              color: "white",
              marginTop: "10px",
              "&:hover": { background: "green" },
            }}
          >
            {" "}
            Add
          </Button>
          <Button onClick={handleClose} sx={{ marginTop: "10px" }}>
            {" "}
            Close
          </Button>
        </Box>
      </Modal>
    </Box>
  );
};

export default PolicyAssistant;
