import React, { useEffect, useCallback, useRef, useState } from "react";
import "./AskQuestion.scss";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Chip,
  FormControl,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { commonService } from "helpers/common.service";
import TypingLine from "./TypingLine";
import MicOffIcon from '@mui/icons-material/MicOff';
import { useReactToPrint } from "react-to-print";
import Spinner from "common_components/PageLoader";
import Modal from "@mui/material/Modal";

import UpdateIcon from "@mui/icons-material/Update";
import { useTheme } from "@mui/material/styles";
import SendIcon from "@mui/icons-material/Send";

import { Failed } from "common_components/toaster/toaster";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import MicIcon from '@mui/icons-material/Mic';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faRobot } from "@fortawesome/free-solid-svg-icons";
import { useLocation, useNavigate } from "react-router-dom";
import AgiraLogo from "asset/images/agirasurelogo.png";
import SendIconL from "asset/images/Group.svg";
import Skeleton from "@mui/material/Skeleton";
import "./Chat.scss";
import SideSuggestions from "./SideSuggestions";
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import LikeDislike from "./LikeDislike";
import { MicOff } from "@mui/icons-material";
import { useSelector } from "react-redux";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  // border: '2px solid #000',
  boxShadow: 24,
  pt: 4,
  px: 4,
  pb: 3,
};

interface Message {
  sender: "user" | "ai";
  text: string;
}

const ClaimInquiry = () => {
  const [content, setContent] = useState<any>([]);
  const [text, setText] = useState<string>("");
  const [loader, setLoader] = useState<boolean>(false);
  const [activateSkeleton, setActivateSkeleton] = useState<boolean>(false);
  const [error, setError] = useState(false);
  const [claimNoerror, setClaimNoError] = useState<any>(false);
  const navigate = useNavigate();
  const [helperText, setHelperText] = useState("");
  const [loaderType, setLoaderType] = useState<boolean>(false);
  const [disablesubmit, setdisableSubmit] = useState(true);
  const ref = useRef<HTMLDivElement>(null);
  const [modal, setModal] = useState<boolean>(false);
  const [addclaimNo, setAddClaimno] = useState<any>();
  const [sugesstions, setSugesstions] = useState<string>("");
  const [messages, setMessages] = useState<Message[]>([]);
  const [input, setInput] = useState<string>("");
  const [micOff, setMicOff] = useState(false);
  const messagesRef = useRef<HTMLDivElement>(null);
  const location = useLocation();
  const { yourKey, activeFrameWork } = location?.state || {};
  const frameworkData = useSelector((state: any) => state.activeFramework.frameworkData);
  const [activeFrame, setActiveFrame] = useState<string>(activeFrameWork || frameworkData);

  const theme = useTheme();
  const componentRef = useRef<any>();
  const chatBoxRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (chatBoxRef.current) {
      chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;
    }
  }, [messages]);

  const handleSendMessage = () => {
    if (chatBoxRef.current) {
      chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;
    }
  };
  useEffect(() => {
    const updatedFrame = activeFrameWork === "" ? frameworkData : activeFrameWork;
    setActiveFrame(updatedFrame);
  }, [activeFrameWork, frameworkData]);

  const getSuggestionDetails = async () => {
    setLoaderType(true);
    try {
      const response = await commonService.getServices(`/file-claim/${yourKey}`);
      if (response.status === 200) {
        // setClaimantDetails(response.data?.data[0]);
        setLoaderType(false);

      }
    } catch (error: any) {

      // Failed(error?.response?.data?.message);

    }
  };

  const sendMessage = async () => {

    setSugesstions(yourKey);
    setLoaderType(true);
    setActivateSkeleton(true);
    getSuggestionDetails();

    if (input.trim()) {
      const userMessage: Message = { sender: "user", text: input };
      setMessages((prevMessages) => [...prevMessages, userMessage]);

      try {
        setTimeout(() => setInput(""), 100);
        const urlPost = (activeFrame === "Llama" && frameworkData !== "Longchain") || frameworkData === "Llama"
          ? "/llama-enquire-claim"
          : "/enquire-claim";

        const response = await commonService.postServiceSeperateBaseUrl(urlPost, {
          question: `${input} of the claim number ${yourKey}`,
        });

        const aiManipulate = response?.data?.data;
        const textWithBreaks = aiManipulate.replace(/\n/g, "<br />");
        const textWithStrong = textWithBreaks.replace(/\*\*(.*?)\*\*/g, "<strong>$1</strong>");

        if (textWithStrong) {
          setActivateSkeleton(false);
        }

        const aiMessage: Message = {
          sender: "ai",
          text: textWithStrong,
        };
        setMessages((prevMessages) => [...prevMessages, aiMessage]);
        setLoaderType(false);
        setInput("");
        resetTranscript();

        handleSendMessage();

      } catch (error: any) {
        const aiMessage: Message = {
          sender: "ai",
          text: error?.response?.data?.message || "Failed to ge the response"
        };
        setMessages((prevMessages) => [...prevMessages, aiMessage]);
        setLoaderType(false);
        setActivateSkeleton(false);
        setInput("");
        resetTranscript();
        handleSendMessage();
      }
    }
  };

  const handleChange = (e: any) => {
    setInput(e.target.value);
    const inputValue = e.target.value;
    setText(inputValue);
    // Split the input into words and count the number of words
    const wordCount = inputValue.split(/\s+/).filter((word: any) => word !== "").length;
    // Validate that there are at least 4 words
    if (wordCount < 3) {
      setdisableSubmit(false);
      setError(true);
      setHelperText("Please enter at least 3 words.");
    } else {
      setdisableSubmit(true);

      setError(false);
      setHelperText("");
    }
    e.preventDefault();
  };

  const handleKeyDown = (e: any) => {
    if (e?.target?.value?.length > 10) {

      if (e.key === "Enter") {
        e.preventDefault();
        sendMessage();
      }
    } else {
      // handleChange(e);
    }

    if (e.key === "Backspace") {
      if (e.target.value.length < 10) {
        resetTranscript();
      }
    }
  };

  const onBeforeGetContentResolve = React.useRef<any>(null);
  React.useEffect(() => {
    if (typeof onBeforeGetContentResolve.current === "function") {
      onBeforeGetContentResolve.current();
    }
  }, [onBeforeGetContentResolve.current]);
  const handleOnBeforeGetContent = React.useCallback(() => {
    setLoader(true);

    // setText("Loading new text...");

    return new Promise((resolve: any) => {
      onBeforeGetContentResolve.current = resolve;

      setTimeout(() => {
        setLoader(false);
        // setText("New, Updated Text!");
        resolve();
      }, 2000);
    });
  }, [setLoader]);
  const handlePrintError = useCallback((e: any) => { }, []);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    copyStyles: true,
    documentTitle: "title",
    suppressErrors: true,
    onBeforeGetContent: handleOnBeforeGetContent,
    onPrintError: handlePrintError,
  });
  const handleTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    setText(inputValue);
    // Split the input into words and count the number of words
    const wordCount = inputValue
      .split(/\s+/)
      .filter((word) => word !== "").length;
    // Validate that there are at least 4 words
    if (wordCount < 3) {
      setdisableSubmit(false);
      setError(true);
      setHelperText("Please enter at least 3 words.");
    } else {
      setdisableSubmit(true);

      setError(false);
      setHelperText("");
    }
  };


  const latestMessage = content[content.length - 1];
  const renderLatestMessage = latestMessage && (
    <TypingLine
      key={content.length - 1}
      question={latestMessage.question}
      fact={latestMessage.answer}
      // err={err}
      isLatest={true} // Since it's the latest message
    />
  );

  const backNavigate = () => {
    setMicOff(false);
    if (!micOff) {
      navigate("/user/ask_anything_Verify");
    }

  };
  const questionInput = (staticText: any) => {
    setInput(staticText);
  };

  const {
    transcript,
    listening,
    resetTranscript,
    browserSupportsSpeechRecognition
  } = useSpeechRecognition();

  useEffect(() => {
    setMicOff(true);
    if (!listening) {
      console.log('Speech recognition service has stopped');
    }
    if (transcript) {
      setInput(transcript);
    }

  }, [listening, transcript]);

  if (!browserSupportsSpeechRecognition) {
    return <span>Browser doesn't support speech recognition.</span>;
  }

  useEffect(() => {
    setInput("");
    resetTranscript();
    SpeechRecognition.stopListening();
    return () => {
      // Cleanup function to stop listening when the component unmounts

      SpeechRecognition.stopListening();

    };
  }, []);

  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (input && inputRef.current) {
      inputRef.current.focus();
    }
  }, [input]);

  return (
    <Box className="askquestionContainer">
      {loader && <Spinner />}

      {messages.length <= 0 ? (
        !addclaimNo && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "70vh",
              margin: 0,
              fontSize: "30px",
              fontWeight: 700,
            }}
          >
            <Box sx={{ minWidth: 277, margin: "0% 22% 0% 14%" }}>
              <div
                style={{
                  display: "flex",

                  marginBottom: "143px",
                  color: "#5553CF",
                  alignItems: "center",
                }}
              >
                <h2>
                  {" "}
                  <KeyboardArrowLeftIcon
                    sx={{
                      color: "black",
                      width: "37px",
                      height: "28px",
                      cursor: "pointer",
                    }}
                    onClick={backNavigate}
                  />
                  Search data against a claim{" "}
                </h2>
                {yourKey && (
                  <Chip sx={{
                    backgroundColor: "#5553cf",
                    color: "#FFF"
                  }} label={yourKey} />
                )}
                {/* <Button
                  variant="outlined"
                  sx={{
                    color: "Black",
                    backgroundColor: "#FFF",
                    border: "0",
                    borderRadius: "7px",
                    display: "flex",
                    height: "3rem",
                    alignItems: "center",
                  }}
                >
                  <span>
                    <UpdateIcon />
                  </span>
                  <span>Chat History</span>
                </Button> */}
              </div>

              <p
                style={{
                  fontSize: "1.5rem",
                  fontWeight: "700",
                  paddingLeft: "20px",
                }}
              >
                {" "}
                Suggestions to begin
              </p>
              <div style={{ display: "flex", gap: "25px" }}>
                <Card
                  variant="outlined"
                  sx={{
                    backgroundColor: "white",
                    borderRadius: "25px",
                    border: "0",
                    width: "32%",
                    boxShadow: 1
                  }}
                >
                  <CardContent>
                    <Typography
                      sx={{ fontSize: 18, display: "flex" }}
                      gutterBottom
                    >
                      <span>
                        Get me the bodily injuries of the claim
                      </span>
                    </Typography>
                  </CardContent>
                  <br></br> <br></br>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                  >
                    <CardActions>
                      <Button>

                        <img src={SendIconL} alt="agiraLogo" width="30px" height="30px" onClick={() =>
                          questionInput(
                            "Get me the bodily injuries of the claim"
                          )
                        } />
                      </Button>
                    </CardActions>
                  </Box>
                </Card>
                <Card
                  variant="outlined"
                  sx={{
                    backgroundColor: "white",
                    borderRadius: "25px",
                    border: "0",
                    width: "32%",
                    boxShadow: 1
                  }}
                >
                  <CardContent>
                    <Typography
                      sx={{ fontSize: 18, display: "flex" }}
                      gutterBottom
                    >
                      <span>
                        When did the accident happen and what is the claim amount?
                      </span>
                    </Typography>
                  </CardContent>
                  <br></br> <br></br>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                  >
                    <CardActions>
                      <Button>

                        <img src={SendIconL} alt="agiraLogo" width="30px" height="30px" onClick={() =>
                          questionInput(
                            "When did the accident happen and what is the claim amount?"
                          )
                        } />
                      </Button>
                    </CardActions>
                  </Box>
                </Card>
                <Card
                  variant="outlined"
                  sx={{
                    backgroundColor: "white",
                    borderRadius: "25px",
                    border: "0",
                    width: "32%",
                    boxShadow: 1
                  }}
                >
                  <CardContent>
                    <Typography
                      sx={{ fontSize: 18, display: "flex" }}
                      gutterBottom
                    >
                      <span>
                        Print the doctor report and injury report
                      </span>
                    </Typography>
                  </CardContent>
                  <br></br> <br></br>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                  >
                    <CardActions>
                      <Button>

                        <img src={SendIconL} alt="agiraLogo" width="30px" height="30px" onClick={() =>
                          questionInput(
                            "Print the doctor report and injury report"
                          )
                        } />
                      </Button>
                    </CardActions>
                  </Box>
                </Card>
              </div>
            </Box>
          </Box>
        )
      ) : (
        <Button
          sx={{
            background: theme.palette.primary.main,
            color: "white",
            "&:hover": {
              background: theme.palette.secondary.light,
            },
            position: "absolute",
            right: "2rem",
            marginTop: "1rem",
            padding: "9px 44px",
            backgroundColor: "#5553cf",
            borderRadius: "13px"
          }}
          onClick={handlePrint}
        >
          Export
        </Button>
      )}
      <div className="chat-container">
        <Box
          className="chat-messages"
          sx={{ height: "0vh", overflowY: "auto", marginBottom: "1rem" }}
          ref={messagesRef}
        ></Box>
        {messages.length > 0 && messages && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              height: "75vh",
              overflowY: "scroll",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                color: "#5553CF",
                alignItems: "center",
                gap: "5px",
                marginLeft: "13%"
              }}
            >
              {" "}
              <KeyboardArrowLeftIcon
                sx={{
                  color: "black",
                  width: "37px",
                  height: "28px",
                  cursor: "pointer",
                }}
                onClick={backNavigate}
              />
              <h1 style={{
                fontSize: "43px"
              }}> Search data against a claim{" "}</h1>
              {yourKey && (
                <Chip sx={{
                  backgroundColor: "#5553cf",
                  color: "#FFF"
                }} label={yourKey} />
              )}
            </div>

            <div
              style={{ width: "62%", overflowY: "auto", scrollBehavior: "smooth", marginLeft: "16rem", marginBottom: "3rem", display: "flex", flexDirection: "column", backgroundColor: "white", borderRadius: "16px" }}
              ref={chatBoxRef}
            >
              {messages.map((msg, index) => (
                <div>
                  <div key={index}
                    style={{
                      display: "flex",
                      alignItems: "flex-start",
                      marginBottom: "0.5rem",
                      padding: "0.5rem",
                      borderRadius: "0.5rem",
                      maxWidth: "80%",
                      width: "auto",
                      backgroundColor: "white",
                      gap: "8px",
                      fontSize: "18px",
                      scrollBehavior: "smooth",
                      lineHeight: "29px",
                      fontWeight: msg.sender === "user" ? "600" : "400",
                      marginTop: msg.sender == "user" ? "1rem" : "0rem",

                    }}>
                    {msg?.sender === "user" ? (
                      <Avatar sx={{ width: 30, height: 30 }} />
                    ) : (
                      <img
                        src={AgiraLogo}
                        alt="agiraLogo"
                        width="30px"
                        height="30px"
                      />
                    )}
                    <div style={{ display: "flex", gap: "5px", marginRight: "0%", fontSize: "20px" }}>
                      <strong>{msg.sender === "user" ? "You:" : "Agira:"}</strong>
                      <div dangerouslySetInnerHTML={{ __html: msg?.text }} />
                    </div>
                  </div>
                  <div style={{ marginLeft: "1%" }}>
                    {msg.sender === "ai" && (<LikeDislike key={index} message={msg} allMessages={messages} chatSection={"Search data against a claim"} />)}
                  </div>
                </div>
              ))}
              {activateSkeleton && (
                <div
                  style={{
                    display: "flex",
                    background: "white",
                    padding: "8px",
                    borderRadius: "15px",
                    maxWidth: "80%",
                    width: "auto",

                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {" "}
                    <img
                      src={AgiraLogo}
                      alt="agiraLogo"
                      width="30px"
                      height="30px"
                    />
                    <strong
                      style={{
                        fontSize: "18px",
                      }}
                    >
                      Agira:{" "}
                    </strong>{" "}
                  </div>

                  <Box sx={{ width: 857, margin: "8px" }}>
                    <Skeleton
                      sx={{
                        display: 'block',
                        backgroundImage: 'linear-gradient(to right, grey, #5553cf)',
                        height: 'auto',
                        marginTop: 0,
                        marginBottom: 0,
                        transformOrigin: '0 55%',
                        WebkitTransform: 'scale(1, 0.60)',
                        MozTransform: 'scale(1, 0.60)',
                        MsTransform: 'scale(1, 0.60)',
                        transform: 'scale(1, 0.60)',
                        borderRadius: '4px / 6.7px',
                        WebkitAnimation: 'animation-c7515d 2s ease-in-out 0.5s infinite',
                        animation: 'animation-c7515d 2s ease-in-out 0.5s infinite',
                        fontSize: '1rem',
                        position: 'relative',
                        overflow: 'hidden',
                        '&::after': {
                          content: '""',
                          position: 'absolute',
                          top: 0,
                          left: 0,
                          width: '100%',
                          height: '100%',
                          backgroundImage: 'linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0))',
                          animation: 'wave-animation 1.5s infinite',
                        },
                        '@keyframes wave-animation': {
                          '0%': {
                            transform: 'translateX(-100%)',
                          },
                          '100%': {
                            transform: 'translateX(100%)',
                          },
                        },
                      }}
                    />
                    <Skeleton animation="wave" sx={{
                      display: 'block',
                      backgroundImage: 'linear-gradient(to right, grey, #5553cf)',
                      height: 'auto',
                      marginTop: 0,
                      marginBottom: 0,
                      transformOrigin: '0 55%',
                      WebkitTransform: 'scale(1, 0.60)',
                      MozTransform: 'scale(1, 0.60)',
                      MsTransform: 'scale(1, 0.60)',
                      transform: 'scale(1, 0.60)',
                      borderRadius: '4px / 6.7px',
                      WebkitAnimation: 'animation-c7515d 4s ease-in-out 4.5s infinite',
                      animation: 'animation-c7515d 2s ease-in-out 0.5s infinite',
                      fontSize: '1rem',
                      position: 'relative',
                      overflow: 'hidden',
                      '&::after': {
                        content: '""',
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        backgroundImage: 'linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0))',
                        animation: 'wave-animation 2s infinite',
                      },
                      '@keyframes wave-animation': {
                        '0%': {
                          transform: 'translateX(-50%)',
                        },
                        '100%': {
                          transform: 'translateX(100%)',
                        },
                      },
                    }} />
                    <Skeleton width="60%" sx={{
                      display: 'block',
                      backgroundImage: 'linear-gradient(to right, grey, #5553cf)',
                      height: 'auto',
                      marginTop: 0,
                      marginBottom: 0,
                      transformOrigin: '0 55%',
                      WebkitTransform: 'scale(1, 0.60)',
                      MozTransform: 'scale(1, 0.60)',
                      MsTransform: 'scale(1, 0.60)',
                      transform: 'scale(1, 0.60)',
                      borderRadius: '4px / 6.7px',
                      WebkitAnimation: 'animation-c7515d 2s ease-in-out 0.5s infinite',
                      animation: 'animation-c7515d 2s ease-in-out 0.5s infinite',
                      fontSize: '1rem',
                      position: 'relative',
                      overflow: 'hidden',
                      '&::after': {
                        content: '""',
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        backgroundImage: 'linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0))',
                        animation: 'wave-animation 2.5s infinite',
                      },
                      '@keyframes wave-animation': {
                        '0%': {
                          transform: 'translateX(-75%)',
                        },
                        '100%': {
                          transform: 'translateX(100%)',
                        },
                      },
                    }} />
                  </Box>
                </div>
              )}
              <div ref={ref} />
            </div>
          </div>
        )}
      </div>

      {/* <Box ref={componentRef}>
        <Box className="chatContent">
          {messages
            .slice(0, content.length - 1)
            .map((line: any, index: number) => (
              <TypingLine
                key={index}
                question={line.question}
                fact={line.answer}
                err={err}
                isLatest={false} // Since it's not the latest message
              />
            ))}
          {renderLatestMessage}

          <div ref={ref} />
        </Box>
      </Box> */}


      <FormControl
        component={"form"}
        sx={{
          position: "absolute",
          bottom: "20px",
          display: "flex",
          justifyContent: "center",
          width: "80%",
        }}
      >
        <Grid item sx={{ display: "flex", alignItems: "center" }}></Grid>
        <Box
          sx={{
            display: "flex",
            margin: "0rem 10rem",
            gap: "10px",
          }}
        >
          <TextField
            id="name"
            type="text"
            value={input}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            placeholder="Ask a Question"
            fullWidth
            autoComplete="off"
            error={error}
            disabled={loaderType}
            inputRef={inputRef}
            sx={{
              ".MuiOutlinedInput-notchedOutline": {
                borderRadius: "15px",
                borderWidth: "0",
              },
              ".MuiOutlinedInput-root": {
                borderRadius: "15px",
              },
              ".MuiOutlinedInput-input": {
                height: "1.25em",
                fontSize: "20px",
                backgroundColor: "#FFF",
                borderRadius: "15px",
                padding: "15px 39px 17px 14px",
              },
            }}
          />
          <Button
            onClick={() => {
              listening && micOff ? SpeechRecognition.stopListening() : SpeechRecognition.startListening({ continuous: true });
            }}
            sx={{
              top: "10px",
              position: "absolute",
              left: "105px",
              color: "#5553CF",
              cursor: "pointer",
            }}
            disabled={!!loaderType}
          >
            {!loaderType && (
              listening ? (
                <MicIcon sx={{ color: "black", width: "37px", height: "28px", cursor: "pointer" }} />
              ) : (
                <MicOffIcon sx={{ color: "black", width: "37px", height: "28px", cursor: "pointer" }} />
              )
            )}
          </Button>
          {/* <div className="loader"></div> */}
          <Button
            onClick={sendMessage}
            sx={{
              top: "8px",
              position: "absolute",
              right: "250px",
              color: "#5553CF",
              cursor: "pointer",
              // width: "6rem",
              // marginLeft: "10px",
              "&:hover": {
                background: "none", // Adjust the rgba value for desired transparency
              }
            }}
            disabled={!loaderType && input ? false : true}
          >

            <img src={SendIconL} alt="agiraLogo" width="30px" height="30px" />

          </Button>

          <Button
            sx={{
              width: "6%",
              borderRadius: "8px",
              padding: "0px 44px",
              background: theme.palette.primary.main,
              color: "white",
              "&:hover": {
                background: theme.palette.secondary.light,
              },
            }}
            disabled={loaderType}
            onClick={() => {
              setError(false);
              setHelperText("");
              setInput("");
              resetTranscript();
            }}
          >
            Clear
          </Button>
        </Box>
        <p style={{ marginLeft: "10.5rem", color: "red" }}>{helperText}</p>
      </FormControl>
      {!!sugesstions && (<SideSuggestions policyNo={sugesstions} />)}
    </Box>
  );
};

export default ClaimInquiry;
