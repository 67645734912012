import React, { useReducer, ChangeEvent } from "react";
import "./AskQuestion.scss";
import { Box, Button, Grid, InputLabel, TextField } from "@mui/material";
import { commonService } from "helpers/common.service";
import Spinner from "common_components/PageLoader";
import { useNavigate } from 'react-router-dom';
import { Failed } from "common_components/toaster/toaster";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import UploadTransactionModal from "./UploadTransactionModal";
import Dictaphone from "./Dictaphone";

// Define initial state type
interface State {
  claimNumber: string;
  claimStatus: string;
  insuredOrgName: string;
  claimantName: string;
  policyNumber: string;
  lineOfBusiness: string;
  dateOfAccident: Date | null;
  dateOfAccidentTo: Date | null;
  dateReported: Date | null;
  dateReportedTo: Date | null;
  loader: boolean;
  modalOpen: boolean;
}

// Define initial state
const initialState: State = {
  claimNumber: "",
  claimStatus: "",
  insuredOrgName: "",
  claimantName: "",
  policyNumber: "",
  lineOfBusiness: "",
  dateOfAccident: null,
  dateOfAccidentTo: null,
  dateReported: null,
  dateReportedTo: null,
  loader: false,
  modalOpen: false,
};

// Define action types
type Action =
  | { type: "SET_FIELD"; field: keyof State; value: any }
  | { type: "SET_LOADER"; value: boolean }
  | { type: "SET_MODAL_OPEN"; value: boolean }
  | { type: "RESET" };

// Define reducer
const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case "SET_FIELD":
      return { ...state, [action.field]: action.value };
    case "SET_LOADER":
      return { ...state, loader: action.value };
    case "SET_MODAL_OPEN":
      return { ...state, modalOpen: action.value };
    case "RESET":
      return initialState;
    default:
      return state;
  }
};

const ClaimSearch: React.FC = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const navigate = useNavigate();

  const handleChange = (field: keyof State) => (event: ChangeEvent<HTMLInputElement>) => {
    dispatch({ type: "SET_FIELD", field, value: event.target.value });
  };


  const handleDateChange = (field: keyof State) => (newValue: Date | null) => {
    dispatch({ type: "SET_FIELD", field, value: newValue });
  };

  const handleFormSubmit = async () => {
    try {
      const hasTrueValue = Object.values(state).some((value) => value);

      if (hasTrueValue) {
        dispatch({ type: "SET_LOADER", value: true });
        const url = `/claims?carrier_claim_number=${state.claimNumber}&employee_name=${state.claimantName}&from_date=${state.dateOfAccident || ''}&to_date=${state.dateOfAccidentTo || ''}&policy_number=${state.policyNumber}&page=1&per_page=10`;
        const response = await commonService.getServices(url);

        if (response.status === 200) {
          dispatch({ type: "SET_LOADER", value: false });
          navigate('/user/claim_search_table', { state: { myData: response.data } });
        }
      } else {
        dispatch({ type: "SET_LOADER", value: false });
        Failed("Kindly fill any one of the field data");
      }
    } catch (e: any) {
      Failed(e.response?.data?.error?.message);
      dispatch({ type: "SET_LOADER", value: false });
    }
  };


  const handleReset = () => {
    dispatch({ type: "RESET" });
  };

  const textFieldStyles = {
    ".MuiOutlinedInput-notchedOutline": {
      borderRadius: "10px",
      borderWidth: "0",
    },
    ".MuiOutlinedInput-root": {
      borderRadius: "10px",
      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        boxShadow: "0 0 5px 0.1px #0400ffc4",
      },
    },
    ".MuiOutlinedInput-input": {
      height: "1.5em",
      fontSize: "16px",
      backgroundColor: "#FFF",
      borderRadius: "10px",
      border: "black 1px solid",
    },
    marginTop: "10px",
  };

  return (
    <Box className="askquestionContainer">
      {state.loader && <Spinner />}
      <Box sx={{ margin: "15px" }}>
        <Grid container spacing={2} sx={{ alignItems: "flex-end" }}>
          <Grid item xs={4}>
            <InputLabel sx={{ fontWeight: "400", color: "#333333", fontSize: "16px" }} htmlFor="claimNumber">
              Carrier Claim Number
            </InputLabel>
            <TextField
              fullWidth
              id="claimNumber"
              value={state.claimNumber}
              onChange={handleChange("claimNumber")}
              sx={textFieldStyles}
            />
          </Grid>
          <Grid item xs={4}>
            <InputLabel sx={{ fontWeight: "400", color: "#333333", fontSize: "16px" }} htmlFor="claimantName">
              Employee Name
            </InputLabel>
            <TextField
              fullWidth
              id="claimantName"
              value={state.claimantName}
              onChange={handleChange("claimantName")}
              sx={textFieldStyles}
            />
          </Grid>
          <Grid item xs={4}>
            <InputLabel sx={{ fontWeight: "400", color: "#333333", fontSize: "16px" }} htmlFor="policyNumber">
              Policy Number
            </InputLabel>
            <TextField
              fullWidth
              id="policyNumber"
              value={state.policyNumber}
              onChange={handleChange("policyNumber")}
              sx={textFieldStyles}
            />
          </Grid>
          <Grid item xs={4}>
            <InputLabel sx={{ fontWeight: "400", color: "#333333", fontSize: "16px", marginBottom: "10px" }} htmlFor="doa">
              Date of Accident
            </InputLabel>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <DatePicker
                    label="From"
                    value={state.dateOfAccident}
                    onChange={handleDateChange("dateOfAccident")}
                    className="date-Picker-custom"
                  />
                </Grid>
                <Grid item xs={6}>
                  <DatePicker
                    label="To"
                    value={state.dateOfAccidentTo}
                    onChange={handleDateChange("dateOfAccidentTo")}
                    className="date-Picker-custom"
                  />
                </Grid>
              </Grid>
            </LocalizationProvider>
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ alignItems: "flex-end", marginTop: "1rem" }}>
          <Grid item xs={6} sx={{ display: "flex", gap: "1rem" }}>
            <Button
              variant="contained"
              sx={{ padding: "9px 37px", color: "White", border: "0", background: "white", "&:hover": { background: "#0e0aed", color: "white" }, borderRadius: "8px", marginTop: "10px" }}
              onClick={() => dispatch({ type: "SET_MODAL_OPEN", value: true })}
            >
              <UploadTransactionModal />
            </Button>
            <Button
              variant="contained"
              sx={{ padding: "9px 37px", color: "White", border: "0", background: "black", "&:hover": { background: "#0e0aed", color: "white" }, borderRadius: "8px", marginTop: "10px" }}
              onClick={handleFormSubmit}
            >
              Search
            </Button>
          </Grid>
          <Grid item xs={6} sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              variant="contained"
              sx={{ padding: "14px 37px", color: "White", border: "0", background: "#0e0aed", "&:hover": { background: "white", color: "black" }, borderRadius: "8px", marginTop: "10px" }}
              onClick={handleReset}
            >
              Reset
            </Button>
          </Grid>
          {/* <Dictaphone/> */}
        </Grid>
      </Box>
    </Box>
  );
};

export default ClaimSearch;
