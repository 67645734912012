import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  Grid,
  Typography,
} from "@mui/material";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import InfoIcon from "@mui/icons-material/Info";
import UpdateIcon from "@mui/icons-material/Update";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import SendIconL from "asset/images/Group.svg";
import Popper from '@mui/material/Popper';
import PopupState, { bindToggle, bindPopper } from 'material-ui-popup-state';
import Fade from '@mui/material/Fade';
import Paper from '@mui/material/Paper';
import ChatHistory from "./ChatHistory";
import { commonService } from "helpers/common.service";
import { Failed } from "common_components/toaster/toaster";


const AskAnythingInception = () => {
  const navigate = useNavigate();
  const frameworkData = useSelector((state: any) => state.activeFramework.frameworkData);
  const user = useSelector((state: any) => state.user);
  const [activeFrameWork, setActiveFrameWork] = useState('');
  const [openChatHistory, setOpenChatHistory] = useState(false);
  const cardRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (frameworkData.length <= 0) {
      getFrameWorkModel();
    }

  }, [frameworkData]);

  const handleClickOutside = (event: MouseEvent) => {
    if (cardRef.current && !cardRef.current.contains(event.target as Node)) {
      setOpenChatHistory(false);
    }
  };
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const getFrameWorkModel = async () => {
    try {
      const url = "/get-models";
      const response = await commonService.getServices(url);
      const activeModelName = response?.data?.active;
      setActiveFrameWork(activeModelName);
    } catch (error: any) {
      console.error('Error:', error);
      Failed(error?.response?.data?.message || 'An error occurred');
    }
  };

  const toggleChatHistory = () => {
    setOpenChatHistory(prevState => !prevState);
  };
  return (
    <>
      <Box
        sx={{
          paddingLeft: "103px",
          paddingRight: "103px",
          marginTop: "25px",
        }}
      >
        <Grid
          container
          spacing={2}
          sx={{
            display: "flex",
            alignItems: "center",
            backgroundColor: "#5553CF",
            color: "white",
            margin: "0px",
            borderRadius: "15px",
          }}
        >
          <Grid
            item
            xs={6}
            sx={{ display: "flex", justifyContent: "space-around", paddingBottom: "16px" }}
            className="grid-flex"
          >
            <div>
              <p style={{ fontSize: "2.5rem", fontWeight: "700" }}>Hi, Admin</p>
              <p
                style={{ fontSize: "2rem", opacity: "0.5", fontWeight: "600" }}
              >
                {" "}
                How would you like to start?
              </p>
            </div>
          </Grid>
          <Grid
            item
            xs={6}
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <div>
              <Button
                variant="outlined"
                sx={{
                  color: !!openChatHistory ? "white" : "white",
                  backgroundColor: !!openChatHistory ? "#4E21B2" : "#4E21B2",
                  border: "0",
                  padding: "1em 1.5em",
                  borderRadius: "7px",
                  display: "flex",
                  alignItems: "center",
                  position: "relative",
                  zIndex: 2, // Ensure button is above the card
                  opacity: openChatHistory ? 0.6 : 1, // Slightly reduce opacity when disabled
                  pointerEvents: openChatHistory ? "none" : "auto", // Disable pointer events when disabled
                }}
                onClick={toggleChatHistory}
                disabled={openChatHistory}
              >

                <span style={{ marginRight: '0.5em',color: !!openChatHistory ? "white" : "white", }}>
                  <UpdateIcon />
                </span>
                <span style={{ color: !!openChatHistory ? "white" : "white", }}>Chat History</span>
              </Button>
              {openChatHistory && (
                <Card
                  ref={cardRef}
                  sx={{
                    position: "absolute",
                    top: "27%", // Position below the button
                    left: "72.5%",
                    zIndex: 1,
                    backgroundColor: "white",
                    borderRadius: "8px", // Border radius for the card
                    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)", // Shadow to stand out
                    padding: "1em",
                    width: "300px", // Adjust width as needed
                  }}
                >
                  <ChatHistory />
                </Card>
              )}
            </div>


          </Grid>
        </Grid>
      </Box >
      <div
        className="Cards-div"
        style={{
          display: "flex",
          justifyContent: "space-evenly",
          marginTop: "40px",
          padding: "0% 14.5%",
          gap: "9%"

        }}
      >
        <div>
          <Box sx={{ minWidth: 377 }}>
            <p
              style={{
                fontSize: "1.5rem",
                fontWeight: "500",
                color: "#5553CF",
                paddingLeft: "20px",
              }}
            >
              {" "}
              Search data against a claim
            </p>

            <Card
              variant="outlined"
              sx={{
                backgroundColor: "white",
                borderRadius: "25px",
                border: "0",
                boxShadow: 1
              }}
            >
              <CardContent sx={{
                padding: "21px"
              }}>
                <Typography sx={{ fontSize: 18, display: "flex", gap: "3px" }} gutterBottom>
                  <span>
                    <InfoIcon
                      type="button"
                      sx={{
                        fontSize: "1.3rem",
                      }}
                    />
                  </span>
                  <span>Make an enquiry on a claim</span>
                </Typography>
              </CardContent>

              <Box
                sx={{
                  display: "flex",
                }}
              >
                <Typography
                  sx={{
                    fontSize: 18,
                    padding: "0% 0% 0% 8%",
                    textWrap: "wrap",
                    width: "83%",
                    opacity: "0.65",
                  }}
                >
                  Get me the bodily injuries of the claim
                </Typography>
                <CardActions>
                  <Button>
                    <img
                      src={SendIconL}
                      alt="agiraLogo"
                      width="30px"
                      height="30px"
                      onClick={() => navigate("/user/ask_anything_Verify")}
                    />
                  </Button>
                </CardActions>
              </Box>
            </Card>
          </Box>


          <Box sx={{ minWidth: 377, paddingTop: "20px", }}>
            <p
              style={{
                fontSize: "1.5rem",
                fontWeight: "500",
                color: "#5553CF",
                paddingLeft: "20px",
              }}
            >
              Explore your data
            </p>
            <Card
              variant="outlined"
              sx={{
                backgroundColor: "white",
                borderRadius: "25px",
                border: "0",
                boxShadow: 1
              }}
            >
              <CardContent sx={{
                padding: "21px"
              }}>
                <Typography sx={{ fontSize: 18, display: "flex", gap: "3px" }} gutterBottom>
                  <span>
                    <InfoIcon
                      sx={{
                        fontSize: "1.3rem",
                      }}
                    />
                  </span>
                  <span>Analyze your claims data</span>
                </Typography>
              </CardContent>

              <Box
                sx={{
                  display: "flex",
                }}
              >
                <Typography
                  sx={{
                    fontSize: 18,
                    padding: "0% 0% 0% 8%",
                    textWrap: "wrap",
                    width: "83%",
                    opacity: "0.65",
                  }}
                >
                  What is the average claim amount processed this year?
                </Typography>
                <CardActions>
                  <Button>

                    <img
                      src={SendIconL}
                      alt="agiraLogo"
                      width="30px"
                      height="30px"
                      onClick={() => navigate("/user/ask_anything_database",
                        {
                          state: { yourKey: activeFrameWork === "" ? frameworkData : activeFrameWork }
                        }
                      )}
                    />
                  </Button>
                </CardActions>
              </Box>
            </Card>
          </Box>



        </div>
        <div>
          <Box sx={{ minWidth: 377 }}>
            <p
              style={{
                fontSize: "1.5rem",
                fontWeight: "500",
                color: "#5553CF",
                paddingLeft: "20px",
              }}
            >
              Summarize Documents
            </p>
            <Card
              variant="outlined"
              sx={{
                backgroundColor: "white",
                borderRadius: "25px",
                border: "0",
                boxShadow: 1
              }}
            >
              <CardContent sx={{
                padding: "21px"
              }}>
                <Typography sx={{ fontSize: 18, display: "flex", gap: "3px" }} gutterBottom>
                  <span>
                    <InfoIcon
                      sx={{
                        fontSize: "1.3rem",
                      }}
                    />
                  </span>
                  <span>Summarize your policy documents</span>
                </Typography>
              </CardContent>
              <Box
                sx={{
                  display: "flex",
                }}
              >
                <Typography
                  sx={{
                    fontSize: 18,
                    padding: "0% 0% 0% 8%",
                    textWrap: "wrap",
                    width: "83%",
                    opacity: "0.65",
                  }}
                >
                  Get me the bodily injuries of the Claim {"<Claim number>"}
                </Typography>
                <CardActions>
                  <Button>
                    <img
                      src={SendIconL}
                      alt="agiraLogo"
                      width="30px"
                      height="30px"
                      onClick={() =>
                        navigate("/user/ask_anything_search_document", {
                          state: { yourKey: activeFrameWork === "" ? frameworkData : activeFrameWork },
                        })
                      }
                    />
                  </Button>
                </CardActions>
              </Box>
            </Card>
          </Box>
          <Box sx={{ minWidth: 377, paddingTop: "20px", }}>
            {activeFrameWork === "Llama" || frameworkData === "Llama" ? <p
              style={{
                fontSize: "1.5rem",
                fontWeight: "500",
                color: "#5553CF",
                paddingLeft: "20px",
              }}
            >

              Visualize the data
            </p> :
              <p
                style={{
                  fontSize: "1.5rem",
                  fontWeight: "500",
                  color: "#5553CF",
                  paddingLeft: "20px",
                }}
              >
                Compare Documents
              </p>}
            <Card
              variant="outlined"
              sx={{
                backgroundColor: "white",
                borderRadius: "25px",
                border: "0",
                boxShadow: 1
              }}
            >
              <CardContent sx={{
                padding: "21px"
              }}>
                <Typography sx={{ fontSize: 18, display: "flex", gap: "3px" }} gutterBottom>
                  <span>
                    <InfoIcon
                      sx={{
                        fontSize: "1.3rem",
                      }}
                    />
                  </span>
                  <span>Find similaties and dfferences in your document</span>
                </Typography>
              </CardContent>

              <Box
                sx={{
                  display: "flex",
                }}
              >
                <Typography
                  sx={{
                    fontSize: 18,
                    padding: "0% 0% 0% 8%",
                    textWrap: "wrap",
                    width: "83%",

                    opacity: "0.65",
                  }}
                >
                  Detect anomalies in the Claim {"<Claim number>"}
                </Typography>
                <CardActions>
                  <Button>

                    <img
                      src={SendIconL}
                      alt="agiraLogo"
                      width="30px"
                      height="30px"
                      onClick={() => navigate("/user/ask_anything_comparsion", {
                        state: { yourKey: activeFrameWork === "" ? frameworkData : activeFrameWork },
                      })}
                    />
                  </Button>
                </CardActions>
              </Box>
            </Card>
          </Box>
        </div>
      </div>
    </>
  );
};

export default AskAnythingInception;
