import axios from "axios";

const pythonBaseUrl = process.env.REACT_APP_API_SECOND_URL;
let chatToken = process.env.REACT_APP_CHAT_KEY;

const getServices = async (url: string): Promise<any> => {
  return new Promise(function (resolve, reject) {
    axios
      .get(url)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        console.error(err);
        reject(err);
      });
  });
};

const patchService = (url: string, body: any): Promise<any> => {
  const apiEndPoint = url;
  return new Promise(function (resolve, reject) {
    axios
      .patch(apiEndPoint, body)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        console.error(err);
        reject(err);
      });
  });
};

const putService = (url: string, body: any): Promise<any> => {
  const apiEndPoint = url;
  return new Promise(function (resolve, reject) {
    axios
      .put(apiEndPoint, body)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        console.error(err);
        reject(err);
      });
  });
};

const deleteService = (url: string): Promise<any> => {
  const apiEndPoint = url;
  return new Promise(function (resolve, reject) {
    axios
      .delete(apiEndPoint)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        console.error(err);
        reject(err);
      });
  });
};

const searchProjects = async (url: string, filter: any): Promise<any> => {
  const apiEndPoint = url;
  return new Promise(function (resolve, reject) {
    axios
      .post(apiEndPoint, { filter: filter })
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        console.error(err);
        reject(err);
      });
  });
};

const postService = async (url: string, data: any): Promise<any> => {
  const apiEndPoint = url;
  return new Promise(function (resolve, reject) {
    axios
      .post(apiEndPoint, { ...data })
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        console.error(err);
        reject(err);
      });
  });
};
const postServiceSeperateBaseUrl = async (
  url: string,
  data: any
): Promise<any> => {
  return new Promise(function (resolve, reject) {
    axios
      .post(url, data, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        console.error(err);
        reject(err);
      });
  });
};

const getServiceSeperateBaseUrl = async (
  url: string,
  data: any
): Promise<any> => {
  return new Promise(function (resolve, reject) {
    axios
      .post(url, data, {
        baseURL: pythonBaseUrl,
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        console.error(err);
        reject(err);
      });
  });
};

const postServiceWithoutData = async (url: string): Promise<any> => {
  const apiEndPoint = url;
  return new Promise(function (resolve, reject) {
    axios
      .post(apiEndPoint)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        console.error(err);
        reject(err);
      });
  });
};
const deleteServiceSeperateBaseUrl = async (
  url: string,
  payloadData: any
): Promise<any> => {
  return new Promise(function (resolve, reject) {
    axios
      .delete(url, {
        baseURL: pythonBaseUrl,
        headers: {
          "Content-Type": "application/json",
        },
        data: payloadData,
      })
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        console.error(err);
        reject(err);
      });
  });
};
const formDataService = (url: string, body: any): Promise<any> => {
  const apiEndPoint = url;
  return new Promise(function (resolve, reject) {
    axios
      .post(apiEndPoint, body, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        console.error(err);
        reject(err);
      });
  });
};

const pdfDownloadService = async (url: string, data: any): Promise<any> => {
  const apiEndPoint = url;
  return new Promise((resolve, reject) => {
    axios
      .post(apiEndPoint, data, {
        responseType: "blob",
      })
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        console.error(err);
        reject(err);
      });
  });
};



const pdfViewService = async (url: string, data: any): Promise<Blob> => {
  return new Promise((resolve, reject) => {
    axios
      .post(url, data, {
        responseType: 'arraybuffer',
      })
      .then((response) => {
        const blob = new Blob([response.data], { type: 'application/pdf' });
        resolve(blob);
      })
      .catch((err) => {
        console.error(err);
        reject(err);
      });
  });
};

const pdfService = async (url: string): Promise<any> => {
  const apiEndPoint = url;
  return new Promise(function (resolve, reject) {
    axios
      .get(apiEndPoint, {
        responseType: "arraybuffer",
      })
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        console.error(err);
        reject(err);
      });
  });
};
const dropDownPoint = async (url: string): Promise<any> => {
  const apiEndPoint = url;
  return new Promise(function (resolve, reject) {
    axios
      .get(apiEndPoint, {
        baseURL: pythonBaseUrl,
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        console.error(err);
        reject(err);
      });
  });
};
const getSettingsDropDown = async (url: string): Promise<any> => {
  const apiEndPoint = url;
  return new Promise(function (resolve, reject) {
    axios
      .get(apiEndPoint, {
        baseURL: pythonBaseUrl,
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        console.error(err);
        reject(err);
      });
  });
};
const updateModel = async (url: string, data: any): Promise<any> => {
  const apiEndPoint = url;
  return new Promise(function (resolve, reject) {
    axios
      .post(apiEndPoint, data, {
        baseURL: pythonBaseUrl,
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        console.error(err);
        reject(err);
      });
  });
};
const getTemplate = async (url: string): Promise<any> => {
  const apiEndPoint = url;
  return new Promise(function (resolve, reject) {
    axios
      .get(apiEndPoint, {
        baseURL: pythonBaseUrl,
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        console.error(err);
        reject(err);
      });
  });
};
const updateTemplate = async (url: string, data: any): Promise<any> => {
  const apiEndPoint = url;
  return new Promise(function (resolve, reject) {
    axios
      .post(apiEndPoint, data, {
        baseURL: pythonBaseUrl,
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        console.error(err);
        reject(err);
      });
  });
};

export const commonService = {
  postService,
  pdfViewService,
  postServiceSeperateBaseUrl,
  getServiceSeperateBaseUrl,
  deleteServiceSeperateBaseUrl,
  postServiceWithoutData,
  patchService,
  getServices,
  formDataService,
  deleteService,
  putService,
  searchProjects,
  pdfDownloadService,
  pdfService,
  chatToken,
  dropDownPoint,
  getSettingsDropDown,
  updateModel,
  getTemplate,
  updateTemplate,
};
