import React, { useEffect, useReducer } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Chip,
} from "@mui/material";
import { Failed, Success } from "common_components/toaster/toaster";
import { commonService } from "helpers/common.service";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import DownloadIcon from "@mui/icons-material/Download";
import DeleteIcon from "@mui/icons-material/Delete";
import { useLocation } from "react-router-dom";
import Loading from "../../common_components/PageLoader/index";
import { Box } from "@mui/system";
import HistoryIcon from '@mui/icons-material/History';

// Define the initial state and reducer
type State = {
  rows: any[];
  serviceCall: boolean;
  loading: boolean;
};

type Action =
  | { type: "SET_ROWS"; payload: any[] }
  | { type: "SET_SERVICE_CALL"; payload: boolean }
  | { type: "SET_LOADING"; payload: boolean };

const initialState: State = {
  rows: [],
  serviceCall: false,
  loading: false,
};

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case "SET_ROWS":
      return { ...state, rows: action.payload };
    case "SET_SERVICE_CALL":
      return { ...state, serviceCall: action.payload };
    case "SET_LOADING":
      return { ...state, loading: action.payload };
    default:
      return state;
  }
};

const ViewDocument: React.FC = () => {
  const location = useLocation();
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    if (location?.state) {
      const newID = location?.state?.id;
      getViewDocument(newID);
      dispatch({ type: "SET_SERVICE_CALL", payload: false });
    }
  }, [location?.state, state.serviceCall]);

  const getViewDocument = async (newID: any) => {
    dispatch({ type: "SET_LOADING", payload: true });
    try {
      const fileDownload = await commonService.getServices(`/file-id/${newID}`);
      dispatch({ type: "SET_ROWS", payload: fileDownload?.data?.data });
    } catch (error: any) {
      Failed(error?.response?.data?.message);
    } finally {
      dispatch({ type: "SET_LOADING", payload: false });
    }
  };

  const handleView = async (id: any) => {
    dispatch({ type: "SET_LOADING", payload: true });
    try {
      const fileDownload = await commonService.pdfViewService("/get-signed-url", { file_id: id });
      const fileURL = URL.createObjectURL(fileDownload);
      window.open(fileURL, "_blank");
    } catch (error: any) {
      Failed(error?.response?.data?.message);
    } finally {
      dispatch({ type: "SET_LOADING", payload: false });
    }
  };

  const handleDelete = async (id: any) => {
    dispatch({ type: "SET_LOADING", payload: true });
    try {
      const fileDelete = await commonService.postService("/delete", { identifier: id });
      if (fileDelete) {
        Success("Delete Successfully");
        dispatch({ type: "SET_SERVICE_CALL", payload: true });
      }
    } catch (error: any) {
      Failed(error?.response?.data?.message);
    } finally {
      dispatch({ type: "SET_LOADING", payload: false });
    }
  };

  const handleDownload = async (id: any, fileName: string) => {
    dispatch({ type: "SET_LOADING", payload: true });
    try {
      const response = await commonService.pdfDownloadService("/get-signed-url", { file_id: id });
      const blob = new Blob([response.data], { type: "application/pdf" });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error: any) {
      Failed(error?.response?.data?.message);
    } finally {
      dispatch({ type: "SET_LOADING", payload: false });
    }
  };

  const goBack = () => {
    window.history.back();
  };

  return (
    <>
      {state.loading && <Loading />}
      <div style={{ display: "flex", justifyContent: "flex-end", margin: "10px" }}>
        <Button onClick={() => dispatch({ type: "SET_SERVICE_CALL", payload: true })}>
          <HistoryIcon />
        </Button>
      </div>
      {state.rows.length > 0 ? (
        <TableContainer component={Paper} sx={{ borderRadius: "15px", height: "72vh" }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Document ID</TableCell>
                <TableCell>Document Name</TableCell>
                <TableCell>Document Type</TableCell>
                <TableCell>Document Status</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {state?.rows?.map((row: any) => (
                <TableRow key={row?.id}>
                  <TableCell>{row?.file_id}</TableCell>
                  <TableCell>{row?.file_name}</TableCell>
                  <TableCell>{row?.category}</TableCell>
                  <TableCell>
                    <Chip
                      label={row?.status}
                      color={
                        row?.status === "Pending" ? "info" : row?.status === "Failed" ? "error" : "success"
                      }
                    />
                  </TableCell>
                  <TableCell sx={{ display: "flex" }}>
                    <Button onClick={() => handleView(row?.file_id)}>
                      <RemoveRedEyeIcon />
                    </Button>
                    <Button onClick={() => handleDownload(row?.file_id, row?.file_name)}>
                      <DownloadIcon />
                    </Button>
                    <Button onClick={() => handleDelete(row?.file_id)}>
                      <DeleteIcon />
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <div style={{ display: "flex", justifyContent: "space-evenly", marginTop: "10px" }}>
          <h1>No Data Found</h1>
        </div>
      )}
      <Button
        onClick={goBack}
        variant="outlined"
        sx={{
          padding: "14px 37px",
          color: "Black",
          border: "0",
          background: "White",
          "&:hover": { background: "#0e0aed", color: "white" },
          borderRadius: "8px",
          marginTop: "10px",
        }}
      >
        Back
      </Button>
    </>
  );
};

export default ViewDocument;
