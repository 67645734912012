import React, { useState } from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { useDropzone } from "react-dropzone";

import Upload from "asset/images/Upload_Big_Arrow.svg";
import { commonService } from "helpers/common.service";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Failed, Success } from "common_components/toaster/toaster";
import "./flexlist.scss";
const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: "15px",
};

export default function TransitionsModal(props: any) {
  const [open, setOpen] = React.useState(false);
  const [dropDown, setDropDown] = React.useState([]);
  const [file, setFile] = React.useState<File | null>(null);
  const [category, setCategory] = React.useState("");
  const [subCategory, setSubCategory] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [projectFilter, setProjectFilter] = React.useState("");
  const [refId, setRefId] = React.useState("");
  const [policyNo, setPolicyNo] = React.useState("");
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [dropDownSubCategory, setDropDownSubCategory] = React.useState([]);
  const [loading, setIsLoading] = useState<boolean>(false);

  React.useEffect(() => {
    setRefId(props?.policyId);
    setPolicyNo(props?.policyNumber);
  }, []);

  const onDrop = (acceptedFiles: File[]) => {
    const uploadedFile = acceptedFiles[0];
    if (
      uploadedFile &&
      (uploadedFile.type === "image/jpeg" ||
        uploadedFile.type === "image/png" ||
        uploadedFile.type === "application/pdf") &&
      uploadedFile.size <= 10000000
    ) {
      setFile(uploadedFile);
    }
  };

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  React.useEffect(() => {
    getModal();
    getSubCategory();
  }, []);

  const getModal = async () => {
    try {
      const getModalResponse = await commonService.getServices("/data_model");
      setDropDown(getModalResponse?.data?.data);
    } catch (error: any) {
      Failed(error?.response?.data?.message);
    }
  };

  const getSubCategory = async () => {
    try {
      const getModalResponse = await commonService.getServices("/sub_category");
      setDropDownSubCategory(getModalResponse?.data?.data);
    } catch (error: any) {
      Failed(error?.response?.data?.message);
    }
  };

  const handleUpload = async () => {
    setIsLoading(true);
    try {
      if (file) {
        const formData = new FormData();
        formData.append("files", file);
        formData.append("model", category);
        formData.append("subCategory", subCategory ? subCategory : category);
        formData.append("policy_referance_id", props?.policyId);
        formData.append("file_description", description);
        formData.append("claim_number", props?.policyNumber);
        let UploadUrl = "/upload-files";
        const response = await commonService.formDataService(
          UploadUrl,
          formData
        );
        if (response.status == 200) {
          Success(response?.data?.message);
          setIsLoading(false);
          onModalClose();
        }
      }
    } catch (error: any) {
      setIsLoading(false);
      Failed(error.response.data.message);
    }
  };
  const updateProjectFilter = (val: any) => {
    setCategory(val);
  };

  const onModalClose = () => {
    setFile(null);
    setCategory("");
    setDescription("");
    setSubCategory("");
    handleClose();
  };
  return (
    <div>
      <Button
        sx={{
          color: "white",
        }}
        onClick={handleOpen}
      >
        Upload Document
      </Button>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <div
              style={{
                textAlign: "center",
              }}
            >
              <FormControl sx={{ minWidth: "100%" }}>
                <InputLabel id="demo-simple-select-label">
                  Choose a category
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                   className="select-custom-style3"
                  onChange={(e) => {
                    updateProjectFilter(e?.target?.value);
                  }}
                  value={category}
                  IconComponent={KeyboardArrowDownIcon}
                  sx={{
                    ".MuiOutlinedInput-notchedOutline": {
                      border: "none",
                    },
                    "&.MuiSelect-select": {
                      borderRadius: "15px",
                  
                    },
                    ".MuiOutlinedInput-input":{
    
                    },
                    borderRadius: "15px",
                  }}
                >
                  {dropDown?.map((p: any, data_model_id: number) => (
                    <MenuItem key={data_model_id} value={p?.data_model_id}>
                      {p?.category}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {category == "2" && (
                <FormControl sx={{ minWidth: "100%" }}>
                  <InputLabel id="subCateogry">
                    Choose a Sub-Category
                  </InputLabel>
                  <Select
                    className="select-custom-style4"
                    labelId="subCateogry"
                    id="subCateogry"
                    value={subCategory}
                    onChange={(e) => setSubCategory(e.target.value as string)}
                    sx={{
                      ".MuiOutlinedInput-notchedOutline": {
                        border: "none",
                      },
                      "&.MuiSelect-select": {
                        borderRadius: "15px",
                      },
                      marginTop: "10px",
                      borderRadius: "15px",
                    }}
                    IconComponent={KeyboardArrowDownIcon}
                  >
                    {dropDownSubCategory?.map(
                      (p: any, data_model_id: number) => (
                        <MenuItem key={data_model_id} value={p?.data_model_id}>
                          {p?.category}
                        </MenuItem>
                      )
                    )}
                  </Select>
                </FormControl>
              )}
              <TextField
                fullWidth
                id="description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                placeholder="Description"
                sx={{
                  ".MuiOutlinedInput-notchedOutline": {
                    borderRadius: "15px",
                    borderWidth: "0",
                  },
                  ".MuiOutlinedInput-root": {
                    borderRadius: "15px",
                  },
                  ".MuiOutlinedInput-input": {
                    height: "1em",
                    fontSize: "20px",
                    backgroundColor: "#F5F7FA",
                    borderRadius: "15px",
                  },
                  marginTop: "10px",
                }}
              />{" "}
              {file ? (
                <div style={{ marginTop: "10px" }}>
                  <img src={Upload} alt="upload" />
                  <p
                    style={{
                      fontWeight: "bold",
                      fontSize: "16px",
                    }}
                  >
                    Document Uploaded
                  </p>
                  <p>{file.name}</p>
                  <p> (PDF max 10MB)</p>
                </div>
              ) : (
                <div style={{ marginTop: "10px" }} {...getRootProps()}>
                  <input {...getInputProps()} />
                  <img src={Upload} alt="upload" />
                  <p
                    style={{
                      fontWeight: "bold",
                      fontSize: "16px",
                    }}
                  >
                    Upload Document
                  </p>
                  <p> (PDF max 10MB)</p>
                </div>
              )}
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "space-between"
              }}
            >
              <Button
                variant="outlined"
                sx={{
                  padding: "9px 37px",
                  color: "black",
                  border: "0",
                  background: "#dbd4d49e",
                  "&:hover": { background: "#0e0aed", color: "white" },
                  borderRadius: "8px",
                  marginTop: "10px",
                }}
                onClick={onModalClose}
              >
                Cancel
              </Button>
              <Button
                variant="outlined"
                sx={{
                  padding: "14px 37px",
                  color: "White",
                  border: "0",
                  background: "#0e0aed",
                  "&:hover": { background: "white", color: "black" },
                  borderRadius: "8px",
                  marginTop: "10px",
                }}
                onClick={handleUpload}
                disabled={
                  category === "generic-ocr"
                    ? !file || !description || !category || !subCategory
                    : !file || !description || !category
                }
              >
                {!loading ? "Upload" : <div className="loader-upload-claim"></div>}
              </Button>
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
