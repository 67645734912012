import React, { useReducer, useEffect } from 'react';
import { Button, FormControl, MenuItem, Select, Tooltip } from '@mui/material';
import ThumbUpIcon from '@mui/icons-material/ThumbUpOffAltRounded';
import ThumbDownAltIcon from '@mui/icons-material/ThumbDownAlt';
import DislikePopup from './DislikePopup';
import { commonService } from 'helpers/common.service';
import { Failed, Success } from 'common_components/toaster/toaster';
import ContentCopyIcon from '@mui/icons-material/ContentCopyRounded';
import TextToSpeech from './TextToSpeech'; // Import the TextToSpeech component
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import "./Chat.scss";

interface Msg {
  sender: string;
  text: string;
}

type Messages = Msg[];

interface LikeDislikeProps {
  initialLikes?: number;
  initialDislikes?: number;
  message: Msg;
  allMessages: Messages;
  chatSection: string;
}

interface State {
  likes: number;
  dislikes: number;
  isLiked: boolean;
  isDisliked: boolean;
  modalOpen: boolean;
  difficultiesData: string;
  improvementsData: string;
  textToCopy: string;
  copySuccess: string;
  saveGptVersion: string;
  getVersion: [];
}

type Action =
  | { type: 'LIKE' }
  | { type: 'DISLIKE' }
  | { type: 'SET_MODAL_OPEN'; value: boolean }
  | { type: 'SET_DIFFICULTIES_DATA'; payload: string }
  | { type: 'SET_IMPROVEMENTS_DATA'; payload: string }
  | { type: 'SET_TEXT'; payload: string }
  | { type: 'COPY_SUCCESS'; payload: string }
  | { type: 'COPY_FAILURE'; payload: string }
  | { type: 'SAVE_GPT_VERSION'; payload: string }
  | { type: 'GET_VERSION'; payload: [] };

const initialState = (initialLikes: number, initialDislikes: number): State => ({
  likes: initialLikes,
  dislikes: initialDislikes,
  isLiked: false,
  isDisliked: false,
  modalOpen: false,
  difficultiesData: "",
  improvementsData: "",
  textToCopy: '',
  copySuccess: '',
  saveGptVersion: "",
  getVersion: [],
});

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'LIKE':
      if (state.isLiked) {
        return { ...state, likes: state.likes - 1, isLiked: false };
      }
      return {
        ...state,
        likes: state.likes + 1,
        dislikes: state.isDisliked ? state.dislikes - 1 : state.dislikes,
        isLiked: true,
        isDisliked: false,
      };
    case 'DISLIKE':
      if (state.isDisliked) {
        return { ...state, dislikes: state.dislikes - 1, isDisliked: false };
      }
      return {
        ...state,
        dislikes: state.dislikes + 1,
        likes: state.isLiked ? state.likes - 1 : state.likes,
        isDisliked: true,
        isLiked: false,
      };
    case 'SET_DIFFICULTIES_DATA':
      return { ...state, difficultiesData: action.payload };
    case 'SET_IMPROVEMENTS_DATA':
      return { ...state, improvementsData: action.payload };
    case 'SET_TEXT':
      return { ...state, textToCopy: action.payload };
    case 'COPY_SUCCESS':
      return { ...state, copySuccess: action.payload };
    case 'COPY_FAILURE':
      return { ...state, copySuccess: action.payload };
    case 'SAVE_GPT_VERSION':
      return { ...state, saveGptVersion: action.payload };
    case 'GET_VERSION':
      return { ...state, getVersion: action.payload };
    default:
      return state;
  }
};

const LikeDislike: React.FC<LikeDislikeProps> = ({ initialLikes = 0, initialDislikes = 0, message, allMessages, chatSection }) => {
  const [state, dispatch] = useReducer(reducer, initialState(initialLikes, initialDislikes));

  const handleLike = async (message: Msg, allMessages: Messages) => {
    const messageIndex = allMessages.findIndex(msg => msg.text === message.text);
    if (messageIndex !== -1 && messageIndex > 0) {
      const previousSender = allMessages[messageIndex - 1].text;
      try {
        const url = "/feedback";
        const PostResponse = await commonService.postService(url, {
          message: previousSender,
          response: message?.text,
          feedbackType: "positive",
          difficulties: "",
          improvements: "",
          chat_section: chatSection || "",
        });

        if (PostResponse.status == 200) {
          Success(PostResponse?.data?.message || "Success");
          dispatch({ type: 'LIKE' });
        }
      } catch (e: any) {
        Failed(e.response.data.message || "error");
      }
    } else {
      Failed('Message not found or no previous message.');
    }


  };

  const handleDislike = async (message: Msg, allMessages: Messages, additionalField1: any, additionalField2: any) => {
    const messageIndex = allMessages.findIndex(msg => msg.text === message.text);
    if (messageIndex !== -1 && messageIndex > 0) {
      const previousSender = allMessages[messageIndex - 1].text;
      try {
        const url = "/feedback";
        const PostResponse = await commonService.postService(url, {
          message: previousSender,
          response: message?.text,
          feedbackType: "negative",
          difficulties: additionalField2,
          improvements: additionalField1,
          chat_section: chatSection || "",
        });

        if (PostResponse?.status == 200) {
          Success(PostResponse?.data?.message || "Success");
          dispatch({ type: 'DISLIKE' });
        }
      } catch (e: any) {
        Failed(e?.response?.data?.message || "error");
      }
    } else {
      Failed('Message not found or no previous message.');
    }


  };

  const handleDislikeFeedback = (selectedCategory: string, additionalField2: string,) => {
    dispatch({ type: 'SET_DIFFICULTIES_DATA', payload: selectedCategory });
    dispatch({ type: 'SET_IMPROVEMENTS_DATA', payload: additionalField2 });
    dispatch({ type: 'SET_MODAL_OPEN', value: false });
    handleDislike(message, allMessages, selectedCategory, additionalField2);
  };

  const copyTextToClipboard = async (message: Msg, allMessages: Messages) => {
    const messageIndex = allMessages.findIndex(msg => msg.text === message.text);
    if (messageIndex !== -1 && messageIndex > 0) {
      try {
        await navigator.clipboard.writeText(`${message.text}`);
        dispatch({ type: 'COPY_SUCCESS', payload: 'Text copied to clipboard!' });
        Success("Text copied to clipboard" || "Success");
      } catch (err) {
        console.error('Failed to copy text: ', err);
        dispatch({ type: 'COPY_FAILURE', payload: 'Failed to copy text' });
        Failed('Failed to copy text');
      }
    }
  };
  useEffect(() => {
    getChatGPTModel();
  }, []);
  const getChatGPTModel = async () => {
    try {

      const url = "/get-gpt-models";
      const getChatGptVersion = await commonService.getServices(url);
      const data = getChatGptVersion?.data?.data;

      dispatch({ type: "GET_VERSION", payload: data });

      const compareActive = data.find(
        (x: any) => x?.gpt_model_name === getChatGptVersion?.data?.active
      );
      dispatch({ type: "SAVE_GPT_VERSION", payload: compareActive?.id });


    } catch (error: any) {

      Failed(error?.response?.data?.message);
    }
  };

  const saveChatGptSettings = async (name: any) => {
    try {

      const url = "/set-gpt-model";
      const saveChatOpenAi = await commonService.putService(url, { model_name: name });

      if (saveChatOpenAi.status === 200) {
        Success(saveChatOpenAi?.data?.message);
        getChatGPTModel();
      }
    } catch (e: any) {
      Failed(e?.response?.data?.error);

    }
  };

  const playText = () => {
    // const synth = window.speechSynthesis;
    // const u = new SpeechSynthesisUtterance(message.text);
    // synth.speak(u);
  };
  const updateProjectFilter = (val: any) => {
    dispatch({ type: "SAVE_GPT_VERSION", payload: val.value });
    const compareActive: any = state.getVersion.find((x: any) => val.value === x?.id);
    saveChatGptSettings(compareActive?.gpt_model_name)
  };

  return (
    <div className="parentDiv" style={{ display: "flex" }}>
      <div className='childDiv' style={{ display: "flex", alignItems: "center", background: "#fff", borderRadius: "16px", gap: "2px" }}>

        <TextToSpeech text={message.text} playText={playText} />
        <Tooltip title="Like">
          <ThumbUpIcon style={{
            marginRight: '10px',
            fontSize: '23px',
            color: state.isLiked ? '#00FF00' : 'black',
            cursor: 'pointer',
            opacity:'0.5'
          }} onClick={() => handleLike(message, allMessages)} />
        </Tooltip>

        <div style={{
          cursor: 'pointer',
        }}>

          <DislikePopup onSubmit={handleDislikeFeedback} IconColor={state.isDisliked} />

        </div>
        <div style={{ display: "flex", cursor: "pointer" }}>
          <Tooltip title="Copy">
            <ContentCopyIcon style={{ opacity: '0.5'}} onClick={() => copyTextToClipboard(message, allMessages)}>Copy Paste</ContentCopyIcon>
          </Tooltip>
        </div>
        <div style={{ display: "flex" }}>

        <FormControl sx={{ width: "100%", marginTop: "10px" }}>
              <Select
                className="select-custom-style-chat"
                labelId="chatGptVersion"
                id="chatGptVersion"
                onChange={(e) => updateProjectFilter(e.target)}
                value={state?.saveGptVersion}
                // IconComponent={KeyboardArrowDownIcon}
                sx={{
                  ".MuiOutlinedInput-notchedOutline": {
                    border: "none",
                  },
                  "&.MuiSelect-select": {
                    borderRadius: "16px",

                  },
                  ".MuiOutlinedInput-input": {
                    background: "#fff",

                  },
                  borderRadius: "15px",
                   opacity: '0.5'
                }}
              >
                {state?.getVersion?.map((p: any, ind: any) => (
                  <MenuItem key={ind} value={p?.id}>
                    {p?.gpt_model_name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

        </div>
      </div>
    </div >
  );
};

export default LikeDislike;
