import React, { useEffect, useCallback, useRef, useState } from "react";
import "./AskQuestion.scss";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Chip,
  FormControl,
  Grid,
  Skeleton,
  TextField,
  Typography,
} from "@mui/material";

import { commonService } from "helpers/common.service";
import TypingLine from "./TypingLine";
import MicIcon from '@mui/icons-material/Mic';
import MicOffIcon from '@mui/icons-material/MicOff';
import { useReactToPrint } from "react-to-print";
import Spinner from "common_components/PageLoader";
import { useTheme } from "@mui/material/styles";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import AgiraLogo from "asset/images/agirasurelogo.png";
import SendIconL from "asset/images/Group.svg";
import { useSelector } from "react-redux";
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import "./Chat.scss";
import { useLocation, useNavigate } from "react-router-dom";
import LikeDislike from "./LikeDislike";
import { Dialog, IconButton, DialogContent } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  // border: '2px solid #000',
  boxShadow: 24,
  pt: 4,
  px: 4,
  pb: 3,
};

type Message = {
  sender: string;
  text: string;
  image?: string; // Optional image property
};

const ClaimInsights = () => {
  const [content, setContent] = useState<any>([]);
  const [text, setText] = useState<string>("");
  const [loader, setLoader] = useState<boolean>(false);
  const [error, setError] = useState(false);
  const [loaderType, setLoaderType] = useState<boolean>(false);
  const [helperText, setHelperText] = useState("");
  const [disablesubmit, setdisableSubmit] = useState(true);
  const ref = useRef<HTMLDivElement>(null);
  const [addclaimNo, setAddClaimno] = useState<any>();
  const [messages, setMessages] = useState<Message[]>([]);
  const [input, setInput] = useState<string>("");
  const navigate = useNavigate();
  const theme = useTheme();
  const componentRef = useRef<any>();
  const [activateSkeleton, setActivateSkeleton] = useState<boolean>(false);
  const [imagePreview, setImagePreview] = useState<any>(null);
  const location = useLocation();
  const { yourKey } = location?.state;
  const frameworkData = useSelector((state: any) => state.activeFramework.frameworkData);
  const [open, setOpen] = useState(false);

  const handleOpen = (e: any) => {
    setImagePreview(e?.image);
    setOpen(true);
   
  }
  const handleClose = () => setOpen(false);
  const chatBoxRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (chatBoxRef.current) {
      chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;
    }
  }, [messages]);

  const handleSendMessage = () => {

    if (chatBoxRef.current) {
      chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;
    }
  };

  const sendMessage = async () => {
    setLoaderType(true);
    setActivateSkeleton(true);

    if (input.trim()) {
      const userMessage: Message = { sender: "user", text: input };
      setMessages((prevMessages) => [...prevMessages, userMessage]);

      try {
        setTimeout(() => {
          setInput("");
        }, 100);

        // Determine the URL based on conditions
        const urlPost = (yourKey === "Llama" && frameworkData !== "Longchain") || frameworkData === "Llama"
          ? "/visual"
          : "/doc-compare";

        // Make the API request
        const response = await commonService.postServiceSeperateBaseUrl(urlPost, { question: input });

        // Extract and process the response
        const aiManipulate = response?.data?.data?.response || response?.data?.data?.airesponse;
        const textWithBreaks = aiManipulate.replace(/\n/g, "<br />");
        const textWithStrong = textWithBreaks.replace(/\*\*(.*?)\*\*/g, "<strong>$1</strong>");

        setActivateSkeleton(false);

        // Prepare the message with optional image
        const imageShow = response?.data?.data?.img_path;
        const aiMessage: Message = {
          sender: "ai",
          text: textWithStrong,
          image: imageShow || undefined, // Assign image if available, otherwise undefined
        };

        // Update messages and reset state
        handleSendMessage();
        setMessages((prevMessages) => [...prevMessages, aiMessage]);
        setLoaderType(false);
        setInput("");
        resetTranscript();

      } catch (error: any) {
        handleSendMessage();
        const aiMessage: Message = {
          sender: "ai",
          text: error?.response?.data?.error || "Check your internet connection"
        };

        setActivateSkeleton(false);
        setMessages((prevMessages) => [...prevMessages, aiMessage]);
        setLoaderType(false);
        setInput("");
        resetTranscript();
      }
    }
  };



  const handleChange = (e: any) => {
    setInput(e.target.value);
    const inputValue = e.target.value;
    setText(inputValue);
    // Split the input into words and count the number of words
    const wordCount = inputValue
      .split(/\s+/)
      .filter((word: any) => word !== "").length;
    // Validate that there are at least 4 words
    if (wordCount < 3) {
      setdisableSubmit(false);
      setError(true);
      setHelperText("Please enter at least 3 words.");
    } else {
      setdisableSubmit(true);

      setError(false);
      setHelperText("");
    }
    e.preventDefault();
  };
  const handleKeyDown = (e: any) => {
    if (e?.target?.value?.length > 10) {

      if (e.key === "Enter") {
        e.preventDefault();
        sendMessage();
      }
    } else {
      // handleChange(e);
    }

    if (e.key === "Backspace") {
      if (e.target.value.length < 10) {
        resetTranscript();
      }
    }
  };

  const onBeforeGetContentResolve = React.useRef<any>(null);
  React.useEffect(() => {
    if (typeof onBeforeGetContentResolve.current === "function") {
      onBeforeGetContentResolve.current();
    }
  }, [onBeforeGetContentResolve.current]);
  const handleOnBeforeGetContent = React.useCallback(() => {
    setLoader(true);

    // setText("Loading new text...");

    return new Promise((resolve: any) => {
      onBeforeGetContentResolve.current = resolve;

      setTimeout(() => {
        setLoader(false);
        // setText("New, Updated Text!");
        resolve();
      }, 2000);
    });
  }, [setLoader]);
  const handlePrintError = useCallback((e: any) => { }, []);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    copyStyles: true,
    documentTitle: "title",
    suppressErrors: true,
    onBeforeGetContent: handleOnBeforeGetContent,
    onPrintError: handlePrintError,
  });
  const handleTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    setText(inputValue);
    // Split the input into words and count the number of words
    const wordCount = inputValue
      .split(/\s+/)
      .filter((word) => word !== "").length;
    // Validate that there are at least 4 words
    if (wordCount < 3) {
      setdisableSubmit(false);
      setError(true);
      setHelperText("Please enter at least 3 words.");
    } else {
      setdisableSubmit(true);

      setError(false);
      setHelperText("");
    }
  };

  React.useEffect(() => {
    if (content.length) {
      ref.current?.scrollIntoView({
        behavior: "smooth",
        block: "end",
      });
    }
    // getGeneralSettings();
  }, [content.length]);

  const latestMessage = content[content.length - 1];
  const renderLatestMessage = latestMessage && (
    <TypingLine
      key={content.length - 1}
      question={latestMessage.question}
      fact={latestMessage.answer}
      // err={err}
      isLatest={true} // Since it's the latest message
    />
  );
  const backNavigate = () => {
    navigate("/user/ask_anything");
  };
  const questionInput = (staticText: any) => {
    setInput(staticText);
  };
  const {
    transcript,
    listening,
    resetTranscript,
    browserSupportsSpeechRecognition
  } = useSpeechRecognition();

  useEffect(() => {
    if (!listening) {
      console.log('Speech recognition service has stopped');

    }
    if (transcript) {
      setInput(transcript);
    }
  }, [listening, transcript]);

  if (!browserSupportsSpeechRecognition) {
    return <span>Browser doesn't support speech recognition.</span>;
  }

  useEffect(() => {
    setInput("");
    resetTranscript();
    SpeechRecognition.stopListening();
    return () => {
      // Cleanup function to stop listening when the component unmounts

      SpeechRecognition.stopListening();

    };
  }, []);


  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (input && inputRef.current) {
      inputRef.current.focus();
    }
  }, [input]);
  return (
    <Box className="askquestionContainer" >
      {loader && <Spinner />}

      {messages.length <= 0 ? (
        !addclaimNo && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "70vh",
              margin: 0,
              fontSize: "30px",
              fontWeight: 700,
            }}
          >
            <Box sx={{ minWidth: 277, margin: "0% 22% 0% 14%" }}>
              <div
                style={{
                  display: "flex",
                  marginBottom: "143px",
                  color: "#5553CF",
                  alignItems: "center",
                }}
              >
                {yourKey === "Llama" || frameworkData === "Llama" ? <h2>
                  <KeyboardArrowLeftIcon
                    sx={{
                      color: "black",
                      width: "37px",
                      height: "28px",
                      cursor: "pointer",
                    }}
                    onClick={backNavigate}
                  />
                  Visualize the data
                </h2> : <h2>
                  <KeyboardArrowLeftIcon
                    sx={{
                      color: "black",
                      width: "37px",
                      height: "28px",
                      cursor: "pointer",
                    }}
                    onClick={backNavigate}
                  />
                  Compare Documents
                </h2>}
                {/* <Button
                  variant="outlined"
                  sx={{
                    color: "Black",
                    backgroundColor: "#FFF",
                    border: "0",
                    borderRadius: "7px",
                    display: "flex",
                    height: "3rem",
                    alignItems: "center",
                  }}
                >
                  <span>
                    <UpdateIcon />
                  </span>
                  <span>Chat History</span>
                </Button> */}
              </div>

              <p
                style={{
                  fontSize: "1.5rem",
                  fontWeight: "700",
                  paddingLeft: "20px",
                }}
              >
                {" "}
                Suggestions to begin
              </p>
              <div style={{ display: "flex", gap: "25px" }}>
                <Card
                  variant="outlined"
                  sx={{
                    backgroundColor: "white",
                    borderRadius: "25px",
                    border: "0",
                    width: "32%",
                    boxShadow: 1
                  }}
                >
                  <CardContent>
                    <Typography
                      sx={{ fontSize: 18, display: "flex" }}
                      gutterBottom
                    >
                      <span>
                        Summarize the differences in accord form and WC5020 for
                        Claim {"<Claim number>"}
                      </span>
                    </Typography>
                  </CardContent>
                  <br></br> <br></br>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                  >
                    <CardActions>

                      <Button>
                        <img src={SendIconL} alt="agiraLogo" width="30px" height="30px" onClick={() =>
                          questionInput(
                            "Summarize the differences in accord form and WC5020 for Claim <Claim number>"
                          )
                        } />
                      </Button>
                    </CardActions>
                  </Box>
                </Card>
                <Card
                  variant="outlined"
                  sx={{
                    backgroundColor: "white",
                    borderRadius: "25px",
                    border: "0",
                    width: "32%",
                    boxShadow: 1
                  }}
                >
                  <CardContent>
                    <Typography
                      sx={{ fontSize: 18, display: "flex" }}
                      gutterBottom
                    >
                      <span>
                        Any anomalies detected in the doctor report for Claim
                        {"<Claim number>"}
                      </span>
                    </Typography>
                  </CardContent>
                  <br></br> <br></br>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                  >
                    <CardActions>
                      <Button>
                        <img src={SendIconL} alt="agiraLogo" width="30px" height="30px" onClick={() =>
                          questionInput(
                            "Any anomalies detected in the doctor report for Claim <Claim number>"
                          )
                        } />

                      </Button>

                    </CardActions>
                  </Box>
                </Card>
                <Card
                  variant="outlined"
                  sx={{
                    backgroundColor: "white",
                    borderRadius: "25px",
                    border: "0",
                    width: "32%",
                    boxShadow: 1
                  }}
                >
                  <CardContent>
                    <Typography
                      sx={{ fontSize: 18, display: "flex" }}
                      gutterBottom
                    >
                      <span>
                        Compare between the {"<Claim1>"} and {"<Claim2>"}
                      </span>
                    </Typography>
                  </CardContent>
                  <br></br><br></br>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                  >
                    <CardActions>

                      <Button>
                        <img src={SendIconL} alt="agiraLogo" width="30px" height="30px" onClick={() =>
                          questionInput(
                            "Compare between the <Claim1> and <Claim2>"
                          )
                        } />

                      </Button>
                    </CardActions>
                  </Box>
                </Card>
              </div>
            </Box>
          </Box>
        )
      ) : (
        <Button
          sx={{
            background: theme.palette.primary.main,
            color: "white",
            "&:hover": {
              background: theme.palette.secondary.light,
            },
            position: "absolute",
            right: "2rem",
            marginTop: "1rem",
            padding: "9px 44px",
            backgroundColor: "#5553cf",
            borderRadius: "13px"
          }}
          onClick={handlePrint}
        >
          Export
        </Button>
      )}

      {messages.length > 0 && messages && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            height: "75vh",
            overflowY: "scroll",
          }}
        >
          <div
            style={{
              display: "flex",
              color: "#5553CF",
              alignItems: "center",
              margin: "0% 11.5%"
            }}
          >
            <KeyboardArrowLeftIcon
              sx={{
                color: "black",
                width: "37px",
                height: "28px",
                cursor: "pointer",
              }}
              onClick={backNavigate}
            />
            {yourKey === "Llama" || frameworkData === "Llama" ? <p style={{

              fontWeight: "700",
              color: "#5553CF",
              fontSize: "40px"
            }}>Visualize the data </p> : <p style={{

              fontWeight: "700",
              color: "#5553CF",
              fontSize: "40px"
            }}>Compare Documents </p>}
          </div>
          <div
            style={{ width: "72%", overflowY: "auto",scrollBehavior: "smooth", marginLeft: "14rem", marginBottom: "3rem", display: "flex", flexDirection: "column", backgroundColor: "white", borderRadius: "16px" }}
            ref={chatBoxRef}
         >
            {messages.map((msg, index) => (
              <div>
                <div
                  key={index}
                  style={{
                    display: "flex",
                    alignItems: "flex-start",
                    marginBottom: "0.5rem",
                    padding: "0.5rem",
                    borderRadius: "1rem",
                    backgroundColor: "white",
                    width: "60%",
                    fontSize: "18px",
                    lineHeight: "29px",
                    fontWeight: msg.sender === "user" ? "600" : "400",
                    scrollBehavior: "smooth",
                    gap: "8px",
                    marginTop: msg.sender == "user" ? "1rem" : "0rem",
                  }}
                >
                  {msg?.sender === "user" ? (
                    <Avatar sx={{ width: 30, height: 30 }} />
                  ) : (
                    <img
                      src={AgiraLogo}
                      alt="agiraLogo"
                      width="30px"
                      height="30px"
                    />
                  )}
                  <div style={{ display: "flex", gap: "5px" }}>
                    <strong>{msg.sender === "user" ? "You:" : "Agira:"}</strong>

                    <div dangerouslySetInnerHTML={{ __html: msg?.text }} />

                  </div>
                </div>
                {msg.image && msg.sender === "ai" && (
                  <div>
                    <img
                      src={msg.image!}
                      alt="Uploaded Preview"
                      style={{
                        maxWidth: '536px',
                        maxHeight: '292px',
                        marginLeft: '100px',
                        borderRadius: '8px',
                        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                        cursor: "pointer"
                      }}
                      onClick={()=>handleOpen(msg)}
                    />
                  </div>
                )}


                <Dialog
                  open={open}
                  onClose={handleClose}
                  fullScreen
                  PaperProps={{
                    style: {
                      backgroundColor: 'transparent', // Optional: to remove background color
                      boxShadow: 'none' // Optional: to remove box shadow
                    }
                  }}
                >
                  <IconButton
                    edge="start"
                    color="inherit"

                    style={{
                      position: 'absolute',
                      top: '16px',
                      right: '16px',
                      zIndex: 1201 // Ensures the button is above other content
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                  <DialogContent
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      height: '100%',
                      overflow: 'hidden'
                    }}
                  >
                    <img
                      src={imagePreview!}
                      alt="Uploaded Preview"
                      style={{
                        maxWidth: '100%',
                        maxHeight: '100%',
                        width: 'auto',
                        height: 'auto',
                        cursor: "pointer"

                      }}
                      onClick={handleClose}
                    />
                  </DialogContent>
                </Dialog>
                <div style={{ marginLeft: "1%" }}>
                  {msg.sender === "ai" && (<LikeDislike message={msg} allMessages={messages} chatSection={"Compare Documents"} />)}
                </div>
              </div>
            ))}
            {activateSkeleton && (
              <div
                style={{
                  display: "flex",
                  background: "white",
                  width: "60%",
                  padding: "8px",
                  borderRadius: "15px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {" "}
                  <img
                    src={AgiraLogo}
                    alt="agiraLogo"
                    width="30px"
                    height="30px"
                  />
                  <strong
                    style={{
                      fontSize: "18px",
                    }}
                  >
                    Agira:{" "}
                  </strong>{" "}
                </div>

                <Box sx={{ width: 857, margin: "8px" }}>
                  <Skeleton
                    sx={{
                      display: 'block',
                      backgroundImage: 'linear-gradient(to right, grey, #5553cf)',
                      height: 'auto',
                      marginTop: 0,
                      marginBottom: 0,
                      transformOrigin: '0 55%',
                      WebkitTransform: 'scale(1, 0.60)',
                      MozTransform: 'scale(1, 0.60)',
                      MsTransform: 'scale(1, 0.60)',
                      transform: 'scale(1, 0.60)',
                      borderRadius: '4px / 6.7px',
                      WebkitAnimation: 'animation-c7515d 2s ease-in-out 0.5s infinite',
                      animation: 'animation-c7515d 2s ease-in-out 0.5s infinite',
                      fontSize: '1rem',
                      position: 'relative',
                      overflow: 'hidden',
                      '&::after': {
                        content: '""',
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        backgroundImage: 'linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0))',
                        animation: 'wave-animation 1.5s infinite',
                      },
                      '@keyframes wave-animation': {
                        '0%': {
                          transform: 'translateX(-100%)',
                        },
                        '100%': {
                          transform: 'translateX(100%)',
                        },
                      },
                    }}
                  />
                  <Skeleton animation="wave" sx={{
                    display: 'block',
                    backgroundImage: 'linear-gradient(to right, grey, #5553cf)',
                    height: 'auto',
                    marginTop: 0,
                    marginBottom: 0,
                    transformOrigin: '0 55%',
                    WebkitTransform: 'scale(1, 0.60)',
                    MozTransform: 'scale(1, 0.60)',
                    MsTransform: 'scale(1, 0.60)',
                    transform: 'scale(1, 0.60)',
                    borderRadius: '4px / 6.7px',
                    WebkitAnimation: 'animation-c7515d 4s ease-in-out 4.5s infinite',
                    animation: 'animation-c7515d 2s ease-in-out 0.5s infinite',
                    fontSize: '1rem',
                    position: 'relative',
                    overflow: 'hidden',
                    '&::after': {
                      content: '""',
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      width: '100%',
                      height: '100%',
                      backgroundImage: 'linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0))',
                      animation: 'wave-animation 2s infinite',
                    },
                    '@keyframes wave-animation': {
                      '0%': {
                        transform: 'translateX(-50%)',
                      },
                      '100%': {
                        transform: 'translateX(100%)',
                      },
                    },
                  }} />
                  <Skeleton width="60%" sx={{
                    display: 'block',
                    backgroundImage: 'linear-gradient(to right, grey, #5553cf)',
                    height: 'auto',
                    marginTop: 0,
                    marginBottom: 0,
                    transformOrigin: '0 55%',
                    WebkitTransform: 'scale(1, 0.60)',
                    MozTransform: 'scale(1, 0.60)',
                    MsTransform: 'scale(1, 0.60)',
                    transform: 'scale(1, 0.60)',
                    borderRadius: '4px / 6.7px',
                    WebkitAnimation: 'animation-c7515d 2s ease-in-out 0.5s infinite',
                    animation: 'animation-c7515d 2s ease-in-out 0.5s infinite',
                    fontSize: '1rem',
                    position: 'relative',
                    overflow: 'hidden',
                    '&::after': {
                      content: '""',
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      width: '100%',
                      height: '100%',
                      backgroundImage: 'linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0))',
                      animation: 'wave-animation 2.5s infinite',
                    },
                    '@keyframes wave-animation': {
                      '0%': {
                        transform: 'translateX(-75%)',
                      },
                      '100%': {
                        transform: 'translateX(100%)',
                      },
                    },
                  }} />
                </Box>
              </div>
            )}
            <div ref={ref} />
          </div>
        </div>
      )}


      {addclaimNo && (
        <Chip
          sx={{
            textAlign: "center",
            position: "absolute",
            top: "81.5%",
            left: "50%",
            fontSize: "15px",
            padding: "25px",
          }}
          label={`Claim No : ${addclaimNo}`}
          variant="outlined"
        />
      )}

      <FormControl
        // onSubmit={(e) => handleSubmit(e)}
        component={"form"}
        sx={{
          position: "absolute",
          bottom: "20px",
          display: "flex",
          justifyContent: "center",
          width: "80%",
        }}
      >
        <Grid item sx={{ display: "flex", alignItems: "center" }}></Grid>
        <Box
          sx={{
            display: "flex",
            margin: "0rem 10rem",
            gap: "10px",
          }}

        >
          <TextField
            id="name"
            type="text"
            value={input}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            placeholder="Ask a Question"
            fullWidth
            autoComplete="off"
            error={error}
            disabled={loaderType}
            inputRef={inputRef}
            sx={{
              ".MuiOutlinedInput-notchedOutline": {
                borderRadius: "15px",
                borderWidth: "0",
              },
              ".MuiOutlinedInput-root": {
                borderRadius: "15px",
              },
              ".MuiOutlinedInput-input": {
                height: "1.25em",
                fontSize: "20px",
                backgroundColor: "#FFF",
                borderRadius: "15px",
                padding: "15px 39px 17px 14px",
              },
            }}
          />
          {listening ? <Button
            // onClick={sendMessage}
            onClick={(e) => { e.preventDefault(); SpeechRecognition.stopListening() }}
            sx={{
              top: "10px",
              position: "absolute",
              left: "105px",
              color: "#5553CF",
              cursor: "pointer",
              // width: "6rem",
              // marginLeft: "10px",
            }}
            disabled={!loaderType ? false : true}
          >
            {!loaderType &&
              // <img src={SendIconL} alt="agiraLogo" width="30px" height="30px" />
              <MicIcon sx={{
                color: "black",
                width: "37px",
                height: "28px",
                cursor: "pointer",
              }} />


            }
          </Button> : <Button
            // onClick={sendMessage}
            onClick={(e) => { e.preventDefault(); SpeechRecognition.startListening({ continuous: true }) }}
            sx={{
              top: "10px",
              position: "absolute",
              left: "105px",
              color: "#5553CF",
              cursor: "pointer",
              // width: "6rem",
              // marginLeft: "10px",
            }}
            disabled={!loaderType ? false : true}
          >
            {!loaderType &&
              // <img src={SendIconL} alt="agiraLogo" width="30px" height="30px" />
              <MicOffIcon sx={{
                color: "black",
                width: "37px",
                height: "28px",
                cursor: "pointer",
              }} />


            }
          </Button>}
          <Button
            onClick={sendMessage}
            sx={{
              top: "8px",
              position: "absolute",
              right: "250px",
              color: "#5553CF",
              cursor: "pointer",
              // width: "6rem",
              // marginLeft: "10px",
              "&:hover": {
                background: "none", // Adjust the rgba value for desired transparency
              }
            }}
            disabled={!loaderType && input ? false : true}
          >

            <img src={SendIconL} alt="agiraLogo" width="30px" height="30px" />

          </Button>

          <Button
            sx={{
              width: "6%",
              borderRadius: "8px",
              padding: "0px 44px",
              background: theme.palette.primary.main,
              color: "white",
              "&:hover": {
                background: theme.palette.secondary.light,
              },
            }}
            onClick={() => {
              setError(false);
              setHelperText("");
              setInput("");
              resetTranscript();
            }}
            disabled={loaderType}
          >
            Clear
          </Button>
        </Box>
        <p style={{ marginLeft: "10.5rem", color: "red" }}>{helperText}</p>
      </FormControl>


    </Box>
  );
};

export default ClaimInsights;
